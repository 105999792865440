import React from 'react';
import intl from 'react-intl-universal';

const styles = {
    color: '#000',
    fontWeight: 'bold',
    textUnderlinePosition: 'under',
};

class CaseIdAnchor extends React.Component {
    render() {
        const {href, text} = this.props;
        return (
            <a
                onClick={e => e.stopPropagation()}
                style={styles}
                href={href}
                target="_blank"
                aria-label={intl.get('ariaLabelCaseLinkNewWindow', {caseId: text})}
                {...this.props}>
                {text}
            </a>
        );
    }
}

export default CaseIdAnchor;

import React from 'react';

import {MuiThemeProvider, createTheme} from '@material-ui/core/styles';
import {indigo, pink} from '@material-ui/core/colors';

const vmeBlue = '#000';
const theme = createTheme({
    fontFamily: 'Roboto, sans-serif',
    typography: {
        useNextVariants: true,
        h5: {
            fontWeight: 'bold',
        },
        h6: {
            color: indigo[600],
            fontSize: '1.5em',
            fontWeight: 'bold',
        },
    },
    overrides: {
        MuiFormLabel: {
            root: {
                color: '#4d4d4d',
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: vmeBlue,
            },
        },
        MuiPickersToolbarButton: {
            toolbarBtn: {
                color: 'white',
            },
        },
        MuiPickersDay: {
            isSelected: {
                backgroundColor: vmeBlue,
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: vmeBlue,
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: vmeBlue,
            },
        },
        MuiButton: {
            root: {
                '&$disabled': {
                    color: '#606060 !important',
                },
            },
            label: {
                'z-index': 1,
            },
        },
        MuiTableRow: {
            root: {
                '&:hover': {
                    backgroundColor: '#f5f5f5 !important',
                },
                outline: '',
            },
        },
        MuiTableSortLabel: {
            root: {
                '&:focus': {
                    '& $icon': {
                        opacity: 0.7,
                    },
                },
            },
            active: {
                '& $icon': {
                    opacity: '1 !important',
                },
            },
        },
        MuiTouchRipple: {
            rippleVisible: {
                opacity: 0.15,
                transform: 'scale(1)',
            },
            '@keyframes mui-ripple-enter': {
                '0%': {
                    transform: 'scale(0)',
                    opacity: 0,
                },
                '100%': {
                    transform: 'scale(1)',
                    opacity: 0.15,
                },
            },
            '@keyframes mui-ripple-pulsate': {
                '0%': {
                    transform: 'scale(1.1)',
                },
                '50%': {
                    transform: 'scale(0.95)',
                },
                '100%': {
                    transform: 'scale(1.1)',
                },
            },
        },
    },
    palette: {
        primary: {
            light: vmeBlue,
            main: vmeBlue,
            dark: vmeBlue,
        },
        secondary: {main: pink.A700},
        error: {
            main: '#d32f2f',
        },
    },
});

export const withTheme = WrappedComponent => {
    class WithTheme extends React.Component {
        render() {
            return (
                <MuiThemeProvider theme={theme}>
                    <WrappedComponent {...this.props} />
                </MuiThemeProvider>
            );
        }
    }

    return WithTheme;
};

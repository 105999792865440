const FraudCaseTypes = {
    DLBP_FRAUD_EVENT: 'DLBP Fraud Event',
    DAC_FRAUD_EVENT: 'DAC Fraud Event',
    DAP_FRAUD_EVENT: 'DAP Fraud Event',
    TRANSACTION_PROOFS: 'Transaction Proofs',
    TRANSACTION_QUERY: 'Transaction Query',
    LOCKBOX: 'Lockbox',
    LOCKBOX_MANAGEMENT: 'Lockbox Management',
};

export default Object.freeze(FraudCaseTypes);

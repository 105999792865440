const defaultState = () => ({
    agentId: '',
    caseManagementUserId: '',
    roles: [],
    language: 'en',
    caseManagementTokenExists: null,
    isFetching: true,
    sessionTimeoutWarningSeconds: 1500,
});

export default function reducer(state, action) {
    if (!state) {
        return defaultState();
    }
    switch (action.type) {
        case 'FETCH_USERINFO_FULFILLED':
            return {
                ...state,
                ...action.payload,
                isFetching: false,
            };
        case 'FETCH_USERINFO_PENDING':
            return {
                ...state,
                isFetching: true,
            };
        case 'FETCH_USERINFO_REJECTED':
            return {
                ...state,
                caseManagementTokenExists: false,
                isFetching: false,
            };
        default:
    }
    return state;
}

export function hasValidRole(roles) {
    if (!Array.isArray(roles)) {
        return false;
    }
    for (let i = 0, size = roles.length; i < size; i++) {
        switch (roles[i]) {
            case 'fraud':
            case 'lockbox':
            case 'proofs':
                return true;
            default:
        }
    }
    return false;
}

// Ensure agent only has one *known* role, and it being the correct role. Ignore all roles other than "lockbox", "fraud", and "proofs"
export const isCallCenterAgent = roles =>
    roles.length > 0 &&
    roles.includes('lockbox') &&
    !roles.includes('fraud') &&
    !roles.includes('proofs'); // lockbox role only
export const isLegalAgent = roles =>
    roles.length > 0 &&
    roles.includes('proofs') &&
    !roles.includes('lockbox') &&
    !roles.includes('fraud'); // proofs role only

import React from 'react';
import {connect} from 'react-redux';

import * as fromReferenceData from '../../app/reducers/referenceDataReducer';

export const withReferenceData = WrappedComponent => {
    class WithReferenceData extends React.Component {
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    const mapStateToProps = state => ({
        getDisplayNameFor: fromReferenceData.getDisplayNameFor(state.referenceData),
    });

    return connect(mapStateToProps)(WithReferenceData);
};

import intl from 'react-intl-universal';

import enLocaleData from './en.json';
import frLocaleData from './fr.json';

const defaultLocale = 'en';
const SUPPORTED_LOCALES = ['en', 'fr'];

let currentLocale = defaultLocale;

export const setLocale = () => {
    // store locales data under full locale key
    // this is a temporary fix for not actually having country-specific locales in system..
    let localesMap = {
        en: enLocaleData,
        fr: frLocaleData,
    };

    // localeList is retrieved from browser
    // attempt to iterate through the locale list and find the first locale that either:
    // 1 - fully match one of supported locales
    // 2 - partially match one of supported locales (language code)
    // if no match found at all, will default to defaultLocale
    const localeList = getBrowserLocaleList();
    for (let i = 0; i < localeList.length; i++) {
        let locale = localeList[i];
        let indexOfSupportedLocale = SUPPORTED_LOCALES.indexOf(locale);
        const splitLocale = locale.split('-');
        if (indexOfSupportedLocale === -1) {
            // full locale not matched, try split locale (lang code only)
            indexOfSupportedLocale = SUPPORTED_LOCALES.indexOf(splitLocale[0]);
            if (indexOfSupportedLocale === -1) {
                // lang locale not matched, try a different locale
                continue;
            } else {
                // lang locale matched
                currentLocale = SUPPORTED_LOCALES[indexOfSupportedLocale];
                break;
            }
        } else {
            // full locale matched
            currentLocale = SUPPORTED_LOCALES[indexOfSupportedLocale];
            break;
        }
    }

    return intl.init({
        currentLocale: currentLocale,
        locales: localesMap,
        commonLocaleDataUrls: {},
        warningHandler: () => {},
    });
};

// Get browser locale
// Code obtained from: https://stackoverflow.com/a/29106129
export const getBrowserLocaleList = () => {
    const nav = window.navigator;
    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
        return nav.languages;
    }
    // support for other well known properties in browsers
    // nav.language didn't work out. try some other options
    const browserLanguagePropertyKeys = [
        'language',
        'browserLanguage',
        'systemLanguage',
        'userLanguage',
    ];
    for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
        const language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
            return [language];
        }
    }
    return [];
};

export const getBrowserLocale = () => currentLocale;

import intl from 'react-intl-universal';
export const getReasonCodes = function() {
    return [
        {key: 'none', title: intl.get('none')},
        {key: 'syntheticId', title: intl.get('syntheticId')},
        {key: 'unauthorizedTransactions', title: intl.get('confirmedUnauthorizedTransactions')},
        {key: 'noFraud', title: intl.get('confirmedNoFraud')},
        {key: 'unauthorizedProfile', title: intl.get('confirmedUnauthorizedProfile')},
        {key: 'inconclusive', title: intl.get('inconclusiveNotReachable')},
    ];
};

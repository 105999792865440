import {getUserInfo} from '../api/auth';

export const toggleSessionTimerWarning = flag => ({
    type: 'TOGGLE_SESSION_TIMER_WARNING',
    payload: flag,
});

// This can be any call to our API
// Get user info seems like the most "neutral" and safe for this task
export const extendSessionTimer = () => ({
    type: 'FETCH_USERINFO_TO_EXTEND_SESSION',
    payload: getUserInfo(),
});

export const resetSessionTimer = timeoutWarningSeconds => dispatch => {
    dispatch({
        type: 'RESET_SESSION_TIMER',
        payload: {
            timer: setTimeout(() => {
                dispatch(toggleSessionTimerWarning(true));
            }, timeoutWarningSeconds * 1000),
        },
    });
};

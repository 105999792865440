import React from 'react';
import intl from 'react-intl-universal';
import './LandingPage.css';
import queryString from 'query-string';
import vmeLogo from '../../../images/interac-logo.svg';
import {connect} from 'react-redux';
import {showNotification} from '../../actions/notificationActions';

import {Paper, Button} from '@material-ui/core';

@connect(store => ({
    notification: store.notification,
}))
export default class LandingPage extends React.Component {
    componentDidMount() {
        document.title = intl.get('landingPageName').d('DLBP Support Tool - Landing Page');

        const params = queryString.parse(window.location.search);
        const {loggedout} = params;
        if (loggedout) {
            this.props.dispatch(showNotification(intl.get('loggedOut'), 'info'));
        }
    }

    render() {
        return (
            <div className="dialogContainer">
                <Paper className="landingPage" square elevation={10}>
                    <div className="token" role="banner">
                        <div className="vmeLogoBg">
                            <img src={vmeLogo} alt={intl.get('vmeLogo')} width="50" height="50" />
                        </div>
                        <div className="pageName">
                            <h1 style={{fontSize: '1em'}}>{intl.get('authPageName')}</h1>
                        </div>
                    </div>
                    <div className="landingContent" role="main">
                        <div className="directionText">{intl.get('welcome')}</div>
                        <Button
                            className="signInButton"
                            variant="contained"
                            color="primary"
                            aria-label={intl.get('ariaLabelSignInBtn')}
                            onClick={() => (window.location.href = '/auth')}>
                            {intl.get('signin')}
                        </Button>
                        <p className="signInNote">{intl.get('idpRedirectPrompt')}</p>
                    </div>
                </Paper>
            </div>
        );
    }
}

import React from 'react';
import intl from 'react-intl-universal';
import './TopBar.css';
import iconSignOut from '../../../images/signout-icon.png';

class TopBar extends React.Component {
    render() {
        return (
            <div className="topBar" role="banner">
                <div className="pageName" tabIndex={3}>{this.props.pageName}</div>
                <div className="signOut">
                    <div className="agentName" tabIndex={4}>
                        {intl.get('signedInAs', {name: this.props.agentName})}
                    </div>
                    <a href="/logout" tabIndex={5}>
                        <span id="signoutText">{intl.get('signout')}</span>
                        <div id="signoutIcon">
                            <img src={iconSignOut} alt="" width="31" height="22" />
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}

export default TopBar;

import LockboxStatus from '../enumerations/LockboxStatus';

const defaultState = () => {
    return {
        lockboxes: [],
        markedLockboxes: [],
        fetchingLockboxes: false,
        deletingLockbox: false,
        settingLockboxStatus: false,
    };
};

export default function reducer(state, action) {
    if (!state) {
        return defaultState();
    }
    switch (action.type) {
        case 'FETCH_LOCKBOXES_FULFILLED': {
            let lockboxes = action.payload.lockboxes || [];
            return {
                ...state,
                fetchingLockboxes: false,
                lockboxes: lockboxes
                    .sort((a, b) => b.createDate - a.createDate) // sort on createDate so old lockboxes go at the bottom
                    .sort((a, b) => (a.status === LockboxStatus.Revoked ? 1 : -1)) // sort on status so the REVOKED lockboxes go at the bottom
                    .map(l => {
                        l.newStatus = l.status; // Used for button ui enabled/disabled toggle
                        return l;
                    }),
            };
        }

        case 'FETCH_LOCKBOXES_PENDING': {
            return {
                ...state,
                fetchingLockboxes: true,
            };
        }

        case 'FETCH_LOCKBOXES_REJECTED': {
            return {
                ...state,
                fetchingLockboxes: false,
            };
        }

        case 'SET_LOCKBOX_STATUS_FULFILLED': {
            let lockboxes = state.lockboxes || [];
            return {
                ...state,
                lockboxes: lockboxes.map(l => {
                    if (l.id === action.meta.lockboxId) {
                        return {
                            ...l,
                            status: action.meta.status,
                            newStatus: action.meta.status,
                        };
                    }
                    return l;
                }),
                settingLockboxStatus: false,
            };
        }

        case 'SET_LOCKBOX_STATUS_PENDING': {
            return {
                ...state,
                settingLockboxStatus: true,
            };
        }

        case 'SET_LOCKBOX_STATUS_REJECTED': {
            return {
                ...state,
                settingLockboxStatus: false,
            };
        }

        case 'SET_MARKED_LOCKBOXES': {
            return {
                ...state,
                markedLockboxes: action.payload,
            };
        }

        case 'DELETE_LOCKBOX_FULFILLED': {
            let deletedLockboxId = action.meta.lockboxId;
            return {
                ...state,
                deletingLockbox: false,
                lockboxes: state.lockboxes.map(lb =>
                    lb.id === deletedLockboxId
                        ? {
                              ...lb,
                              status: LockboxStatus.Revoked,
                              deleteDate: Math.floor(Date.now() / 1000),
                          }
                        : lb
                ),
            };
        }

        case 'DELETE_LOCKBOX_PENDING': {
            return {
                ...state,
                deletingLockbox: true,
            };
        }

        case 'DELETE_LOCKBOX_REJECTED': {
            return {
                ...state,
                deletingLockbox: false,
            };
        }

        case 'CLEAR_LOCKBOXES': {
            return {
                ...state,
                lockboxes: [],
            };
        }

        default:
    }

    return state;
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import intl from 'react-intl-universal';

import './App.css';

import {Switch, Route, Redirect, withRouter} from 'react-router-dom';
import {withTheme} from '../../hocomponents/WithTheme';

import ActiveTicketsPage from '../../containers/ActiveTicketsPage';
import FindUsersPage from '../../containers/FindUsersPage';
import JiraTokenPage from '../../containers/JiraTokenPage';
import LandingPage from '../../containers/LandingPage';
import InvalidRolesPage from '../../containers/InvalidRolesPage';

import {Snackbar, CircularProgress, Button, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import SnackbarContentWrapper from '../../components/SnackbarContentWrapper';
import {fetchUserInfo} from '../../actions/authActions';
import {hideNotification} from '../../actions/notificationActions';
import {fetchReferenceData} from '../../actions/referenceDataActions';
import {isCallCenterAgent, hasValidRole} from '../../reducers/authReducer';

import {toggleSessionTimerWarning, extendSessionTimer} from '../../actions/sessionTimerActions';

const routes = [
    {
        path: '/find-user',
        exact: true,
        roles: ['fraud', 'lockbox'],
        component: props => <FindUsersPage {...props} />,
    },
    {
        path: '/active-cases',
        exact: true,
        roles: ['fraud', 'proofs'],
        component: props => <ActiveTicketsPage {...props} />,
    },
    {
        path: '/active-cases/:caseId',
        exact: true,
        roles: ['fraud', 'proofs'],
        component: props => <ActiveTicketsPage {...props} />,
    },
    {
        path: '/',
        exact: true,
        component: () => <Redirect to={{pathname: '/active-cases'}} />,
    },
];

const isNotAllowed = (rolesAllowed, actualRoles) => {
    return rolesAllowed.map(x => actualRoles.includes(x)).every(x => x === false);
};

const InvalidRolesPageRoute = ({roles, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            hasValidRole(roles) ? (
                <Redirect
                    to={{
                        pathname: '/',
                        state: {from: props.location},
                    }}
                />
            ) : (
                <InvalidRolesPage />
            )
        }
    />
);

const JiraRoute = ({jiraTokenExists, jiraInvalidUser, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            jiraTokenExists ? (
                <Redirect
                    to={{
                        pathname: '/',
                        state: {from: props.location},
                    }}
                />
            ) : (
                <JiraTokenPage
                    pageName={intl.get('jiraTokenPageName')}
                    jiraInvalidUser={jiraInvalidUser}
                />
            )
        }
    />
);

const PrivateRoute = ({
    component: Component,
    jiraTokenExists,
    allowedRoles,
    actualRoles,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props =>
                actualRoles === undefined || actualRoles.length < 1 ? (
                    <Redirect
                        to={{
                            pathname: '/errorInvalidRoles',
                            state: {from: props.location},
                        }}
                    />
                ) : !hasValidRole(actualRoles) ? (
                    <Redirect
                        to={{
                            pathname: '/errorInvalidRoles',
                            state: {from: props.location},
                        }}
                    />
                ) : isCallCenterAgent(actualRoles) || jiraTokenExists ? (
                    !allowedRoles || !isNotAllowed(allowedRoles, actualRoles) ? (
                        <Component {...props} />
                    ) : isCallCenterAgent(actualRoles) ? (
                        <Redirect
                            to={{
                                pathname: '/find-user',
                                state: {from: props.location},
                            }}
                        />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/active-cases',
                                state: {from: props.location},
                            }}
                        />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: '/jira',
                            state: {from: props.location},
                        }}
                    />
                )
            }
        />
    );
};

class App extends Component {
    componentDidMount() {
        if (
            this.props.location.pathname !== '/landing' &&
            this.props.location.pathname !== '/errorInvalidRoles'
        )
            this.props.dispatch(fetchUserInfo());
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.auth.agentId && this.props.auth.agentId) {
            this.props.dispatch(fetchReferenceData());
        }
    }

    renderSessionTimeoutSnackbar = () => {
        const {sessionTimerState} = this.props;
        const {showSessionExpiryWarning} = sessionTimerState;
        return (
            <Snackbar
                aria-live="assertive"
                className="sessionTimeoutSnackbar"
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={showSessionExpiryWarning}
                onClose={() => this.props.dispatch(toggleSessionTimerWarning(false))}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                ClickAwayListenerProps={{mouseEvent: false}}
                autoHideDuration={null}
                message={<span id="message-id">{intl.get('sessionExpireSoon')}</span>}
                action={[
                    <Button
                        key="extend"
                        color="secondary"
                        variant="contained"
                        size="small"
                        onClick={() => {
                            this.props.dispatch(extendSessionTimer());
                            this.props.dispatch(toggleSessionTimerWarning(false));
                        }}>
                        {intl.get('extendSession')}
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => this.props.dispatch(toggleSessionTimerWarning(false))}>
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        );
    };

    renderGlobalSnackbar = () => {
        const {notificationState} = this.props;
        const {text, isOpen, vertical, horizontal, variant} = notificationState;
        return (
            <Snackbar
                aria-live="assertive"
                anchorOrigin={{vertical, horizontal}}
                open={isOpen}
                onClose={() => this.props.dispatch(hideNotification())}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                autoHideDuration={null}>
                <SnackbarContentWrapper
                    style={{flexWrap: 'nowrap'}}
                    onClose={() => this.props.dispatch(hideNotification())}
                    variant={variant}
                    message={<span id="message-id">{text}</span>}
                />
            </Snackbar>
        );
    };

    renderCircularProgress = () => {
        return (
            <CircularProgress
                style={{position: 'absolute', left: 230, top: 90}}
                size={100}
                aria-label={intl.get('ariaLabelCircularProgress')}
            />
        );
    };

    renderRoutes = () => {
        const {auth} = this.props;
        const {caseManagementTokenExists, caseManagementAuthInvalidUser, roles} = auth;
        return (
            <Switch>
                {routes.map((route, index) => (
                    <PrivateRoute
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                        allowedRoles={route.roles}
                        actualRoles={roles}
                        jiraTokenExists={caseManagementTokenExists}
                        jiraInvalidUser={caseManagementAuthInvalidUser}
                    />
                ))}
                <InvalidRolesPageRoute path="/errorInvalidRoles" exact roles={roles} />
                <JiraRoute
                    path="/jira"
                    exact
                    jiraTokenExists={caseManagementTokenExists}
                    jiraInvalidUser={caseManagementAuthInvalidUser}
                />
            </Switch>
        );
    };

    render() {
        const {auth, notificationState, sessionTimerState} = this.props;
        const {isOpen: showGlobalSnackbar} = notificationState;
        const {agentId, isFetching} = auth;
        const {showSessionExpiryWarning} = sessionTimerState;

        const {pathname} = this.props.location;

        return (
            <div className="App">
                {!agentId ? (
                    pathname === '/errorInvalidRoles' ? (
                        <InvalidRolesPageRoute path="/errorInvalidRoles" exact />
                    ) : (
                        <Route path="/landing" exact component={LandingPage} />
                    )
                ) : (
                    <div aria-busy={isFetching} aria-live="polite">
                        {isFetching ? this.renderCircularProgress() : this.renderRoutes()}
                    </div>
                )}
                <div role="region" aria-label={intl.get('ariaLabelAlertDialogsRegion')}>
                    {showGlobalSnackbar && this.renderGlobalSnackbar()}
                    {showSessionExpiryWarning && this.renderSessionTimeoutSnackbar()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    notificationState: state.notification,
    sessionTimerState: state.sessionTimer,
});

const enchance = compose(
    withTheme,
    withRouter,
    connect(mapStateToProps)
);

export default enchance(App);

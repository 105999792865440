import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {deleteLockbox, setLockboxStatus, setMarkedLockboxes} from '../../actions/lockboxActions';
import {isCallCenterAgent} from '../../reducers/authReducer';
import './LockboxTable.css';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Checkbox,
    Select,
    MenuItem,
    Button,
    CircularProgress,
    TableFooter,
} from '@material-ui/core';

import LockboxStatus from '../../enumerations/LockboxStatus';
import AlertDialog from '../../components/AlertDialog';
import CaseIdAnchor from '../../components/CaseIdAnchor';
import {getFormattedTime} from '../../utils';

let validStatusChange = {};
validStatusChange[LockboxStatus.Active] = [
    LockboxStatus.OnHold,
    LockboxStatus.Suspended,
    LockboxStatus.Revoked,
];
validStatusChange[LockboxStatus.OnHold] = [LockboxStatus.Active, LockboxStatus.Suspended];
validStatusChange[LockboxStatus.Suspended] = [LockboxStatus.Active, LockboxStatus.Revoked];

@connect(store => ({
    lockboxState: store.lockbox,
    selectedUser: store.user.selectedUser,
    ticketSelected: store.ticket.ticketSelected,
    newTicketPending: store.ticket.newTicketPending,
    roles: store.auth.roles,
}))
class LockboxTable extends React.Component {
    constructor(props) {
        super(props);
        this.lockboxFieldsWithoutCases = [
            {key: 'id', title: intl.get('id'), ariaLabel: intl.get('ariaLabelColumnLockboxId')},
            {
                key: 'status',
                title: intl.get('status'),
                ariaLabel: intl.get('ariaLabelColumnLockboxStatus'),
            },
            {
                key: 'createDate',
                title: intl.get('createdTime'),
                ariaLabel: intl.get('ariaLabelColumnLockboxCreatedTime'),
            },
            {
                key: 'deleteDate',
                title: intl.get('deletedTime'),
                ariaLabel: intl.get('ariaLabelColumnLockboxDeletedTime'),
            },
            {
                key: 'devices',
                title: intl.get('devices'),
                ariaLabel: intl.get('ariaLabelColumnLockboxDevices'),
            },
        ];

        this.lockboxFieldsWithCases = [
            ...this.lockboxFieldsWithoutCases,
            {
                key: 'fraudCaseId',
                title: intl.get('fraudCaseId'),
                ariaLabel: intl.get('ariaLabelColumnLockboxFraudCaseId'),
            },
        ];

        this.setLockboxStatusAlertTitle = intl.get('setLockboxStatusAlertTitle');
        this.deleteLockboxAlertTitle = intl.get('deleteLockboxAlertTitle');
        this.deleteLockboxAlertDescription = intl.get('deleteLockboxAlertDescription');
        this.normalCoreIdInputHelperText = intl.get('coreId');
        this.errorCoreIdInputHelperText = intl.get('errorCoreIdInputHelperText');
        this.state = {
            page: 0,
            rowsPerPage: 10,
            openFormDialog: false,
            openConfirmDialog: false,
            currentLockboxId: '',
            invalidCoreIdInput: false,
            buttonDisabled: true,
            activeLockboxes: []
        };
    }

    componentDidMount() {
        const {lockboxState} = this.props;
        const {lockboxes} = lockboxState;
        if (typeof lockboxes === 'undefined') return;
        let activeLockboxes = lockboxes.filter(l => l.status !== LockboxStatus.Revoked);
        if (activeLockboxes.length === 0) return;
        const updateActiveLockboxes = activeLockboxes.map(lb => {return {...lb, prevStatus: lb.status}});
        this.setState({activeLockboxes: updateActiveLockboxes})
    }

    handleLockboxStatusUpdate(lockboxId, status) {
        const {ticketSelected, newTicketPending, roles} = this.props;
        let currentCaseId =
            !isCallCenterAgent(roles) && ticketSelected
                ? ticketSelected.caseId
                : newTicketPending
                ? newTicketPending.caseId
                : null;
        this.props.dispatch(setLockboxStatus(currentCaseId, lockboxId, status));

        this.setState(prevState => {
            const updatedLockboxes = prevState.activeLockboxes.map(lb =>
                lb.id === lockboxId ? { ...lb, status: status, prevStatus: status } : lb
            );
            return { activeLockboxes: updatedLockboxes };
        });
    }

    statusToDisplayText = status => {
        switch (status) {
            case LockboxStatus.Active:
                return intl.get('active').toUpperCase();
            case LockboxStatus.OnHold:
                return intl.get('onhold').toUpperCase();
            case LockboxStatus.Suspended:
                return intl.get('suspended').toUpperCase();
            case LockboxStatus.Unavailable:
                return intl.get('unavailable').toUpperCase();
            default:
                return intl.get('unknownStatus').toUpperCase();
        }
    };

    buildSelectControlsForActiveLockbox = lb => {
        const {roles} = this.props;
        const {settingLockboxStatus} = this.props.lockboxState;
        return settingLockboxStatus ? (
            <CircularProgress
                size={30}
                aria-label={intl.get('ariaLabelCircularProgress')}
                aria-valuemin={0}
                aria-valuemax={1}
            />
        ) : isCallCenterAgent(roles) ? (
            // Call Center agents cannot change a lockbox's status
            <div aria-live="polite">
                {this.statusToDisplayText(
                    lb.status === LockboxStatus.OnHold
                        ? LockboxStatus.Unavailable
                        : lb.status
                )}
            </div>
        ) : (
            <Select
                aria-live="polite"
                value={lb.status || ''}
                onChange={event => {
                    this.setState(prevState => {
                        const updatedLockboxes = prevState.activeLockboxes.map(lockbox =>
                            lockbox.id === lb.id ? { ...lockbox, status: event.target.value, prevStatus: lb.prevStatus
                                    ? lb.prevStatus
                                    : lb.status } : lb
                        );
                        return {
                            activeLockboxes: updatedLockboxes,
                        };
                    });
                    this.openConfirmDialog(lb.id);
                }}>
                <MenuItem
                    value={LockboxStatus.Active}
                    disabled={
                        !validStatusChange[lb.status].includes(LockboxStatus.Active)
                    }>
                    {this.statusToDisplayText(LockboxStatus.Active)}
                </MenuItem>
                <MenuItem
                    value={LockboxStatus.OnHold}
                    disabled={
                        !validStatusChange[lb.status].includes(LockboxStatus.OnHold)
                    }>
                    {this.statusToDisplayText(LockboxStatus.OnHold)}
                </MenuItem>
                <MenuItem
                    value={LockboxStatus.Suspended}
                    disabled={
                        !validStatusChange[lb.status].includes(LockboxStatus.Suspended)
                    }>
                    {this.statusToDisplayText(LockboxStatus.Suspended)}
                </MenuItem>
            </Select>
        );
    };

    buildDeleteButtonForActiveLockbox = lb => {
        const {roles} = this.props;
        const {deletingLockbox} = this.props.lockboxState;
        return deletingLockbox ? (
            <CircularProgress
                color="secondary"
                size={30}
                aria-label={intl.get('ariaLabelCircularProgress')}
                aria-valuemin={0}
                aria-valuemax={1}
            />
        ) : (
            <Button
                aria-live="polite"
                color="secondary"
                size="small"
                variant="contained"
                onClick={() => this.openFormDialog(lb.id)}
                // DEV-13053 Support Tool Disable Delete LB for CC User when LB Not Active
                disabled={
                    lb.status === LockboxStatus.OnHold ||
                    (lb.status !== LockboxStatus.Active && isCallCenterAgent(roles))
                }>
                {intl.get('deleteLockbox')}
            </Button>
        );
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    handleFormDialogCancel() {
        this.setState({openFormDialog: false, invalidCoreIdInput: false, buttonDisabled: true});
    }

    handleFormDialogConfirm() {
        const {currentLockboxId} = this.state;
        const {ticketSelected, newTicketPending, roles} = this.props;
        let currentCaseId =
            !isCallCenterAgent(roles) && ticketSelected
                ? ticketSelected.caseId
                : newTicketPending
                ? newTicketPending.caseId
                : null;
        this.setState({openFormDialog: false, invalidCoreIdInput: false, buttonDisabled: true});
        this.props.dispatch(deleteLockbox(currentCaseId, currentLockboxId));
    }

    handleConfirmDialogCancel() {
        const {currentLockboxId} = this.state;
        this.setState(prevState => {
            const updatedLockboxes = prevState.activeLockboxes.map(lockbox =>
                lockbox.id === currentLockboxId ? { ...lockbox, status: lockbox.prevStatus} : lockbox
            );
            return {
                activeLockboxes: updatedLockboxes,
                openConfirmDialog: false,
            };
        });
    }

    handleConfirmDialogConfirm() {
        this.setState({openConfirmDialog: false});
        const {currentLockboxId, activeLockboxes} = this.state;
        const currentLockbox = activeLockboxes.find(lb => lb.id === currentLockboxId);
        this.handleLockboxStatusUpdate(
            currentLockbox.id,
            currentLockbox.status,
            currentLockbox.prevStatus
        );
    }

    handleCoreIdInput(event) {
        const {selectedUser} = this.props;
        if (event.target.value === selectedUser.dlbpUserId) {
            this.setState({invalidCoreIdInput: false, buttonDisabled: false});
        } else if (!event.target.value) {
            this.setState({invalidCoreIdInput: false, buttonDisabled: true});
        } else {
            this.setState({invalidCoreIdInput: true, buttonDisabled: true});
        }
    }

    openFormDialog(lockboxId) {
        this.setState({openFormDialog: true, currentLockboxId: lockboxId});
    }

    openConfirmDialog(lockboxId) {
        this.setState({openConfirmDialog: true, currentLockboxId: lockboxId});
    }

    handleLockboxMarked(lockbox) {
        const {markedLockboxes} = this.props.lockboxState;

        const markedIndex = markedLockboxes.findIndex(elem => elem.id === lockbox.id);
        let newMarked = [];

        if (markedIndex === -1) {
            newMarked = newMarked.concat(markedLockboxes, lockbox);
        } else if (markedIndex === 0) {
            newMarked = newMarked.concat(markedLockboxes.slice(1));
        } else if (markedIndex === markedLockboxes.length - 1) {
            newMarked = newMarked.concat(markedLockboxes.slice(0, -1));
        } else if (markedIndex > 0) {
            newMarked = newMarked.concat(
                markedLockboxes.slice(0, markedIndex),
                markedLockboxes.slice(markedIndex + 1)
            );
        }

        this.props.dispatch(setMarkedLockboxes(newMarked));
    }

    isMarked = id =>
        (this.props.lockboxState.markedLockboxes || []).findIndex(elem => elem.id === id) !== -1;

    render() {
        const {
            page,
            rowsPerPage,
            activeLockboxes,
            openFormDialog,
            openConfirmDialog,
            invalidCoreIdInput,
            currentLockboxId,
            buttonDisabled,
        } = this.state;
        const {lockboxes} = this.props.lockboxState;
        const {roles} = this.props;
        const lockboxFields = isCallCenterAgent(roles)
            ? this.lockboxFieldsWithoutCases
            : this.lockboxFieldsWithCases;
        let activeLockbox = activeLockboxes.find(lb => lb.id === currentLockboxId);

        return (
            <div aria-live="polite">
                <AlertDialog
                    open={openConfirmDialog}
                    title={this.setLockboxStatusAlertTitle}
                    descriptions={[
                        activeLockbox &&
                            intl.get('createConfirmationMessage', {
                                fromStatus: this.statusToDisplayText(activeLockbox.prevStatus),
                                toStatus: this.statusToDisplayText(activeLockbox.status),
                            }),
                    ]}
                    handleCancel={this.handleConfirmDialogCancel.bind(this)}
                    handleConfirm={this.handleConfirmDialogConfirm.bind(this)}
                />
                <AlertDialog
                    withField={true}
                    open={openFormDialog}
                    title={this.deleteLockboxAlertTitle}
                    descriptions={[this.deleteLockboxAlertDescription]}
                    handleCancel={this.handleFormDialogCancel.bind(this)}
                    handleConfirm={this.handleFormDialogConfirm.bind(this)}
                    handleInput={this.handleCoreIdInput.bind(this)}
                    buttonDisabled={buttonDisabled}
                    error={invalidCoreIdInput}
                    inputHelperText={
                        invalidCoreIdInput
                            ? this.errorCoreIdInputHelperText
                            : this.normalCoreIdInputHelperText
                    }
                />
                <Table
                    tabIndex={0}
                    aria-labelledby="title-user-lockboxes"
                    summary={intl.get('tableSummaryUserLockboxes')}>
                    <TableHead>
                        <TableRow>
                            {lockboxFields.map((field, i) => (
                                <TableCell
                                    key={i}
                                    aria-label={field.ariaLabel}
                                    className="head-cell">
                                    {field.title}
                                </TableCell>
                            ))}
                            {!isCallCenterAgent(roles) && (
                                <TableCell
                                    aria-label={intl.get('ariaLabelColumnMarkUnauthorized')}
                                    className="head-cell">
                                    {intl.get('markUnauthorized')}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {lockboxes
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((lb, i) => {
                                const isMarked = this.isMarked(lb.id);
                                const wasAlreadyReported =
                                    lb.fraudCaseId !== null &&
                                    typeof lb.fraudCaseId !== 'undefined';

                                return (
                                    <TableRow
                                        hover
                                        key={i}
                                        style={
                                            wasAlreadyReported
                                                ? {backgroundColor: '#F8F8F8'}
                                                : isMarked
                                                ? {backgroundColor: '#FCE4EC'}
                                                : {}
                                        }>
                                        {lockboxFields.map((field, i) => {
                                            let value = lb[field.key];
                                            switch (field.key) {
                                                case 'devices':
                                                    value = value.map(x => x.platform).join(', ');
                                                    break;
                                                case 'fraudCaseId':
                                                    value = value ? (
                                                        <CaseIdAnchor
                                                            href={lb.fraudCaseUrl}
                                                            text={value}
                                                        />
                                                    ) : (
                                                        '-'
                                                    );
                                                    break;
                                                case 'createDate':
                                                    value = value ? getFormattedTime(value) : '-';
                                                    break;
                                                case 'deleteDate':
                                                    if (lb.status === LockboxStatus.Revoked) {
                                                        value = value
                                                            ? getFormattedTime(value)
                                                            : '-';
                                                    } else {
                                                        value = this.buildDeleteButtonForActiveLockbox(
                                                            lb
                                                        );
                                                    }
                                                    break;
                                                case 'status':
                                                    if (lb.status === LockboxStatus.Revoked) {
                                                        value = intl.get('statusRevoked');
                                                        break;
                                                    }
                                                    value = this.buildSelectControlsForActiveLockbox(
                                                        lb
                                                    );
                                                    break;
                                                default:
                                                    break;
                                            }
                                            return (
                                                <TableCell key={i} className="row-cell">
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                        {!isCallCenterAgent(roles) && (
                                            <TableCell padding="checkbox" className="row-cell">
                                                {wasAlreadyReported ? null : (
                                                    <Checkbox
                                                        checked={isMarked}
                                                        onClick={() => this.handleLockboxMarked(lb)}
                                                        inputProps={{
                                                            'aria-label': intl.get(
                                                                'ariaLabelCheckboxMarkUnauthorized'
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelDisplayedRows={({from, to, count}) =>
                                    intl.get('paginationTemplate', {
                                        from: from,
                                        to: to,
                                        count: count,
                                    })
                                }
                                count={lockboxes.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                rowsPerPageOptions={[5, 10, 15, 20]}
                                backIconButtonProps={{
                                    'aria-label': intl.get('previousPage'),
                                }}
                                nextIconButtonProps={{
                                    'aria-label': intl.get('nextPage'),
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                SelectProps={{
                                    SelectDisplayProps: {
                                        'aria-label': `${intl.get('rowsPerPage')} ${rowsPerPage}`,
                                        role: 'group',
                                    },
                                }}
                                aria-label={intl.get('ariaLabelTablePagination')}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        );
    }
}

export default LockboxTable;

import axios, {CancelToken} from 'axios';

const CancellationMap = {};
const cancelPreviousRequestFor = fnName => {
    if (fnName in CancellationMap && typeof CancellationMap[fnName].cancel === 'function') {
        CancellationMap[fnName].cancel('Request was cancelled');
    }
    let newCancelSource = CancelToken.source();
    CancellationMap[fnName] = newCancelSource;
    return newCancelSource.token;
};

export const cancelRequests = reqNames => (reqNames || []).forEach(cancelPreviousRequestFor);

export const findVerifiedMeUser = searchCriteria => {
    const url = '/api/findVerifiedMeUser';
    return axios.post(url, {searchCriteria}, {cancelToken: cancelPreviousRequestFor(url)});
};

export const findCasesForUser = verifiedMeUserId => {
    const url = '/api/findCasesForUser';
    return axios.post(url, {verifiedMeUserId}, {cancelToken: cancelPreviousRequestFor(url)});
};

export const findCasesForAgent = () => {
    const url = '/api/findCasesForAgent';
    return axios.get(url, {cancelToken: cancelPreviousRequestFor(url)});
};

export const retrieveCase = caseId => {
    const url = '/api/retrieveCase';
    return axios.post(url, {caseId}, {cancelToken: cancelPreviousRequestFor(url)});
};

export const startCase = caseId => {
    const url = '/api/startCase';
    return axios.post(url, {caseId}, {cancelToken: cancelPreviousRequestFor(url)});
};

export const createCase = (verifiedMeUserId, reasonCode) => {
    const url = '/api/createCase';
    return axios.post(url, {
        verifiedMeUserId,
        reasonCode,
    });
};

export const getLockboxes = verifiedMeUserId => {
    const url = '/api/getLockboxes';
    return axios.post(url, {verifiedMeUserId}, {cancelToken: cancelPreviousRequestFor(url)});
};

export const findTransactions = (verifiedMeUserId, caseId, searchCriteria) => {
    const url = '/api/findTransactions';
    return axios.post(
        url,
        {verifiedMeUserId, caseId, searchCriteria},
        {cancelToken: cancelPreviousRequestFor(url)}
    );
};

export const getReferenceData = language => {
    const url = '/api/getAllRefDataDisplayName';
    return axios.post(url, {language}, {cancelToken: cancelPreviousRequestFor(url)});
};

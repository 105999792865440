import {combineReducers} from 'redux';

import ticketReducer from './ticketReducer';
import transactionReducer from './transactionReducer';
import userReducer from './userReducer';
import lockboxReducer from './lockboxReducer';
import authReducer from './authReducer';
import reportReducer from './reportReducer';
import notificationReducer from './notificationReducer';
import referenceDataReducer from './referenceDataReducer';
import sessionTimerReducer from './sessionTimerReducer';

export default combineReducers({
    ticket: ticketReducer,
    transaction: transactionReducer,
    user: userReducer,
    lockbox: lockboxReducer,
    auth: authReducer,
    report: reportReducer,
    notification: notificationReducer,
    referenceData: referenceDataReducer,
    sessionTimer: sessionTimerReducer,
});

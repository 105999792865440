import {SearchUserBy} from '../enumerations/SearchUserBy';
import {findVerifiedMeUser, findCasesForUser} from '../api/common';

export const fetchUser = (searchBy, query, shouldFetchUserCases) => {
    return dispatch => {
        let queryPayload = {};

        switch (searchBy) {
            case SearchUserBy.CORE_ID:
                queryPayload = {dlbpUserId: query};
                break;
            case SearchUserBy.PSEUDONYM_ID:
                queryPayload = {pseudonymId: query};
                break;
            case SearchUserBy.LICENSE_ID:
                queryPayload = {licenseId: query};
                break;
            case SearchUserBy.ASSET_ID:
                queryPayload = {assetId: query};
                break;
            case SearchUserBy.VME_USER_ID:
                queryPayload = {vmeUserId: query};
                break;
            default:
        }

        return dispatch({
            type: 'FETCH_USER',
            payload: findVerifiedMeUser(queryPayload),
        }).then(res => {
            if (shouldFetchUserCases) dispatch(fetchCasesForUser(res.value.verifiedMeUserId));
        });
    };
};

export const clearUser = () => ({
    type: 'CLEAR_USER',
});

export const selectUser = user => ({
    type: 'SELECT_USER',
    payload: user,
});

export const markUserSynthetic = isSynthetic => ({
    type: 'MARK_USER_SYNTHETIC',
    payload: isSynthetic,
});

export const fetchCasesForUser = verifiedMeUserId => ({
    type: 'FETCH_CASES_FOR_USER',
    payload: findCasesForUser(verifiedMeUserId),
});

export const updateCaseStatus = (caseId, status) => ({
    type: 'UPDATE_CASE_STATUS',
    payload: {caseId, status},
});

export const addNewCase = newCase => ({
    type: 'ADD_NEW_CASE',
    payload: newCase,
});

export const clearSearchError = () => ({
    type: 'CLEAR_SEARCH_ERROR',
});

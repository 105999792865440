const TransactionType = {
    UserCreateLockbox: 'user_createLockbox',
    UserDeleteLockbox: 'user_deleteLockbox',
    UserRecoverLockbox: 'user_recoverLockbox',
    UserCreatePseudonym: 'user_createPseudonym',
    UserClaimPseudonym: 'user_claimPseudonym',
    UserRemovePseudonym: 'user_removePseudonym',
    UserRecoverPseudonym: 'user_recoverPseudonym',
    UserCreateDA: 'user_createDA',
    UserExecuteService: 'user_executeService',
    UserViewAssetForLicense: 'user_viewAssetForLicense',
    UserViewDa: 'user_viewDA',
    UserRetrieveLicenseRequest: 'user_retrieveLicenseRequest',
    UserIssueLicense: 'user_issueLicense',
    UserRetrieveDeviceSecurityData: 'user_retrieveDeviceSecurityData',
    UserAcceptTerms: 'user_acceptTerms',

    DlbpSuspendUser: 'dlbp_suspendUser',
    DlbpResumeUser: 'dlbp_resumeUser',
    DlbpHoldUser: 'dlbp_holdUser',
    DlbpUnholdUser: 'dlbp_unholdUser',
    DlbpDeleteLockbox: 'dlbp_deleteLockbox',
    DlbpProofSearch: 'dlbp_proofSearch',
    DlbpFraudSearch: 'dlbp_fraudSearch',
    DapUpdateAsset: 'dap_updateAsset',

    DacUseLicense: 'dac_useLicense',
};

export default Object.freeze(TransactionType);

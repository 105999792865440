const defaultState = () => {
    return {
        timer: null,
        showSessionExpiryWarning: false,
    };
};

function reducer(state = defaultState(), action) {
    switch (action.type) {
        case 'RESET_SESSION_TIMER':
            if (state.timer) {
                clearTimeout(state.timer);
            }
            return {
                ...state,
                timer: action.payload.timer,
            };
        case 'TOGGLE_SESSION_TIMER_WARNING':
            return {
                ...state,
                showSessionExpiryWarning: action.payload,
            };
        default:
    }

    return state;
}

export default reducer;

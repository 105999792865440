import React from 'react';
import intl from 'react-intl-universal';

import UserSearchBar from '../../components/UserSearchBar';
import CurrentUser from '../../components/CurrentUser';
import CurrentTicket from '../../components/CurrentTicket';
import UserActiveTickets from '../../components/UserActiveTickets';
import ButtonBar from '../../components/ButtonBar';
import UserLockboxes from '../../components/UserLockboxes';
import UserActivity from '../../components/UserActivity';
import ReportDetails from '../../components/ReportDetails';
import WithTopBarSideBar from '../../hocomponents/WithTopBarSideBar';

import {connect} from 'react-redux';

import {clearTicket, createNewTicket, changeNewTicketReason} from '../../actions/ticketActions';
import {clearUser, selectUser} from '../../actions/userActions';
import {
    fetchTransactions,
    clearUserTransactions,
    clearMarkedTransactions,
    setMarkedTransactions,
} from '../../actions/transactionActions';
import {
    setMarkedLockboxes,
    clearMarkedLockboxes,
    clearLockboxes,
} from '../../actions/lockboxActions';
import {showReportDetails, hideReportDetails, submitFraudReport} from '../../actions/reportActions';
import {showNotification} from '../../actions/notificationActions';
import {isCallCenterAgent} from '../../reducers/authReducer';

// Constants
import {getReasonCodes} from '../../constants/ReasonCodes';

import * as api from '../../api/common';

class FindUsersPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showReportDetails: false,
            reasonCode: getReasonCodes()[0].key,
        };
    }

    componentDidMount() {
        document.title = intl.get('findUserPageName').d('DLBP Support Tool - Find User');
    }

    handleReportReasonChange = event => this.setState({reasonCode: event.target.value});

    fetchUserTransactions(searchCriteria) {
        const {ticketState, userState, auth} = this.props;
        const {verifiedMeUserId} = userState.selectedUser;
        const {caseId} = ticketState.newTicketPending;
        const roles = auth.roles;
        this.props.dispatch(fetchTransactions(verifiedMeUserId, caseId, searchCriteria, roles));
    }

    handleChangeNewTicketReason = reason => this.props.dispatch(changeNewTicketReason(reason));

    handleCreateReport = () => this.props.dispatch(showReportDetails());

    backFromReportDetails() {
        this.props.dispatch(clearMarkedTransactions());
        this.props.dispatch(clearMarkedLockboxes());
        this.props.dispatch(hideReportDetails());
    }

    handleFindUserCancel = () => {
        this.props.dispatch(clearTicket());
        this.props.dispatch(clearMarkedTransactions());
        this.props.dispatch(clearUserTransactions());
        this.props.dispatch(hideReportDetails());
    };

    handleCreateNewTicket = (verifiedMeUserId, reasonCode) => {
        const {auth} = this.props;
        const id = auth.caseManagementUserId;

        if (reasonCode === 'none') {
            this.props.dispatch(showNotification(intl.get('mustSelectReason'), 'warning'));
            return;
        }

        this.props.dispatch(createNewTicket(id, verifiedMeUserId, reasonCode));
    };

    handleChangeTransactionsMarked = txns => this.props.dispatch(setMarkedTransactions(txns));

    handleChangeLockboxesMarked(lbs) {
        this.props.dispatch(setMarkedLockboxes(lbs));
    }

    handleTicketSelection = ticket => {
        const {auth} = this.props;

        if (ticket.assignee !== auth.caseManagementUserId) {
            this.props.dispatch(showNotification(intl.get('caseNotAssigned'), 'info'));
            return;
        }

        this.props.dispatch(selectUser({verifiedMeUserId: ticket.verifiedMeUserId}));
        this.props.history.push(`/active-cases/${ticket.caseId}`);
    };

    composeButtonBarNote(userTransactions, markedTransactions, isUserSynthetic) {
        if (isUserSynthetic) {
            return `You marked user as synthetic`;
        } else if (userTransactions.length === 0) {
            return ``;
        } else if (markedTransactions.length > 0) {
            return intl.get('transactionsAdded', {numTransactions: markedTransactions.length});
        }
        return intl.get('createReportInst');
    }

    cancelTicketSelection = () => this.props.dispatch(clearTicket());

    handleSubmitReport() {
        const {ticketState, transactionState, lockboxState} = this.props;

        const {newTicketPending} = ticketState;
        const {markedTransactions} = transactionState;
        const {markedLockboxes} = lockboxState;
        const {reasonCode} = this.state;

        if (reasonCode === 'none') {
            this.props.dispatch(showNotification(intl.get('mustSelectResolution'), 'warning'));
            return;
        }

        const lockboxCreateTransactionIds = (markedLockboxes || []).map(l => l.createTransactionId);
        const markedTransactionIds = (markedTransactions || []).map(t => t.id);

        this.props.dispatch(
            submitFraudReport(newTicketPending.caseId, reasonCode, [
                ...markedTransactionIds,
                ...lockboxCreateTransactionIds,
            ])
        );

        // reset reason code back to default
        this.setState({reasonCode: 'none'});
    }

    renderUserActiveTickets(user, cases, searchError) {
        const {userState} = this.props;
        const {fetchingCasesForUser} = userState;
        return (
            <UserActiveTickets
                user={user}
                tickets={cases}
                onTicketSelection={this.handleTicketSelection}
                idleText={intl.get('noUserRecordSelectedPrompt')}
                searchError={searchError}
                isLoading={fetchingCasesForUser}
            />
        );
    }

    render() {
        const {
            userState,
            transactionState,
            ticketState,
            lockboxState,
            reportState,
            auth,
        } = this.props;
        const {reasonCode} = this.state;

        const {showReportDetails, isSubmitting} = reportState;
        const {newTicketPending, newTicketReason, fetchingNewTicketId} = ticketState;
        const {selectedUser, searchError} = userState;
        const {userTransactions, markedTransactions, fetchingTransactions} = transactionState;
        const {markedLockboxes} = lockboxState;

        const userCases = selectedUser && selectedUser.cases ? selectedUser.cases : [];
        const isUserSynthetic = selectedUser && selectedUser.isSynthetic;

        return (
            <div id="main-content" className="contentArea" role="main">
                <UserSearchBar auth={auth} />
                <div className="contentRow">
                    <CurrentUser user={selectedUser} />
                    {!isCallCenterAgent(auth.roles) && (
                        <CurrentTicket
                            user={selectedUser}
                            newTicketPending={newTicketPending}
                            createNewTicketFn={selectedUser ? this.handleCreateNewTicket : null}
                            handleNewTicketReasonChange={
                                selectedUser ? this.handleChangeNewTicketReason : null
                            }
                            newTicketReason={newTicketReason}
                            isLoading={fetchingNewTicketId}
                        />
                    )}
                </div>

                {!newTicketPending &&
                    this.renderUserActiveTickets(selectedUser, userCases, searchError)}

                {isCallCenterAgent(auth.roles) && selectedUser && selectedUser.verifiedMeUserId && (
                    <UserLockboxes />
                )}

                {newTicketPending && selectedUser && (
                    <div>
                        {showReportDetails ? (
                            <ReportDetails
                                ticket={newTicketPending}
                                lockboxes={markedLockboxes}
                                handleMarkedLockboxesChange={this.handleChangeLockboxesMarked.bind(
                                    this
                                )}
                                transactions={markedTransactions}
                                handleMarkedTransactionsChange={this.handleChangeTransactionsMarked.bind(
                                    this
                                )}
                                reasonCodes={getReasonCodes()}
                                reasonCode={reasonCode}
                                handleReasonCodeChange={this.handleReportReasonChange.bind(this)}
                                isSubmitting={isSubmitting}
                            />
                        ) : (
                            <div>
                                <UserLockboxes />

                                <UserActivity
                                    fetchedTransactions={userTransactions}
                                    fetchTransactions={this.fetchUserTransactions.bind(this)}
                                    handleChangeTransactionsMarked={this.handleChangeTransactionsMarked.bind(
                                        this
                                    )}
                                    isLoading={fetchingTransactions}
                                />
                            </div>
                        )}

                        <ButtonBar
                            cancelFn={
                                showReportDetails
                                    ? this.backFromReportDetails.bind(this)
                                    : this.handleFindUserCancel.bind(this)
                            }
                            cancelTxt={showReportDetails ? intl.get('back') : intl.get('cancel')}
                            submitFn={
                                showReportDetails
                                    ? this.handleSubmitReport.bind(this)
                                    : this.handleCreateReport.bind(this)
                            }
                            submitTxt={
                                showReportDetails
                                    ? intl.get('submitReport')
                                    : intl.get('createReport')
                            }
                            note={this.composeButtonBarNote(
                                userTransactions,
                                markedTransactions,
                                isUserSynthetic
                            )}
                        />
                    </div>
                )}
            </div>
        );
    }

    cancelOngoingRequests = () =>
        api.cancelRequests(
            [api.findVerifiedMeUser, api.findCasesForUser, api.findTransactions, api.startCase].map(
                fn => fn.name
            )
        );

    componentWillUnmount() {
        this.cancelOngoingRequests();

        this.props.dispatch(clearUser());
        this.props.dispatch(clearLockboxes());
        this.props.dispatch(clearUserTransactions());
        this.props.dispatch(clearMarkedTransactions());
        this.props.dispatch(clearTicket());
        this.props.dispatch(hideReportDetails());
    }
}

const mapStateToProps = state => ({
    userState: state.user,
    transactionState: state.transaction,
    ticketState: state.ticket,
    lockboxState: state.lockbox,
    reportState: state.report,
    auth: state.auth,
});

export default connect(mapStateToProps)(WithTopBarSideBar(FindUsersPage));

const defaultState = () => {
    return {
        showReportDetails: false,
        isSubmitting: false,
    };
};

export default function reducer(state, action) {
    if (!state) {
        return defaultState();
    }

    switch (action.type) {
        case 'SUBMIT_FRAUD_REPORT_FULFILLED':
            return {
                ...state,
                showReportDetails: false,
                isSubmitting: false,
            };
        case 'SUBMIT_FRAUD_REPORT_PENDING':
            return {
                ...state,
                isSubmitting: true,
            };
        case 'SUBMIT_FRAUD_REPORT_REJECTED':
            return {
                ...state,
                isSubmitting: false,
            };

        case 'SUBMIT_TRANSACTION_PROOFS_REPORT_FULFILLED':
            return {
                ...state,
                isSubmitting: false,
            };
        case 'SUBMIT_TRANSACTION_PROOFS_REPORT_PENDING':
            return {
                ...state,
                isSubmitting: true,
            };
        case 'SUBMIT_TRANSACTION_PROOFS_REPORT_REJECTED':
            return {
                ...state,
                isSubmitting: false,
            };

        case 'HIDE_REPORT_DETAILS':
            return {
                ...state,
                showReportDetails: false,
            };
        case 'SHOW_REPORT_DETAILS':
            return {
                ...state,
                showReportDetails: true,
            };
        default:
    }

    return state;
}

import intl from 'react-intl-universal';

export const FraudCaseStatus = {
    open: 'Open',
    assigned: 'Assigned',
    investigating: 'Investigating',
    reportSubmitted: 'Report Submitted',
    closed: 'Closed',

    toDo: 'To Do',
    inProgress: 'In Progress',
    done: 'Done',

    proofSubmitted: 'Proof Submitted',
};

export default Object.freeze(FraudCaseStatus);

export const getFraudCaseStatusDisplayText = function() {
    return {
        open: intl.get('open'),
        assigned: intl.get('assigned'),
        investigating: intl.get('investigating'),
        reportSubmitted: intl.get('reportSubmitted'),
        closed: intl.get('closed'),

        toDo: intl.get('todo'),
        inProgress: intl.get('inProgress'),
        done: intl.get('done'),

        proofSubmitted: intl.get('proofSubmitted'),
    };
};

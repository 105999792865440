import React from 'react';
import intl from 'react-intl-universal';
import './CurrentTicket.css';

import {
    Typography,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Button,
    CircularProgress,
} from '@material-ui/core';

import CaseIdAnchor from '../../components/CaseIdAnchor';

import {getFraudCaseStatusDisplayText} from '../../enumerations/FraudCaseStatus';
import {getFraudCaseReasonDisplayText} from '../../enumerations/FraudCaseReason';
import {getFormattedTime} from '../../utils'

class CurrentTicket extends React.Component {
    constructor(props) {
        super(props);
        this.newTicketReasons = [
            {key: 'none', title: intl.get('none')},
            {key: 'syntheticId', title: intl.get('syntheticId')},
            {key: 'unauthorizedTransactions', title: intl.get('unauthorizedTransactions')},
            {key: 'fraudAlert', title: intl.get('fraudAlert')},
        ];
    }

    handleNewTicketReasonChange(event) {
        this.props.handleNewTicketReasonChange(event.target.value);
    }

    onBtnCreateNewClick() {
        const {newTicketReason, user} = this.props;
        this.props.createNewTicketFn(user.verifiedMeUserId, newTicketReason);
    }

    renderKeyValuePair(key, value, label = null) {
        return (
            <div className="currentTicketHolder">
                <span className="title">{key}</span>
                <span className="text">
                    {value}
                    {label}
                </span>
            </div>
        );
    }

    renderDapListOfDates = listOfDates => {
        return listOfDates
            .slice(0, 5) // keep only the first 5 dates
            .sort((a, b) => a - b)
            .map(d => (
                <span className="datetime-pill">
                    {getFormattedTime(d)}
                </span>
            ));
    };

    renderCreateNewTicket(newTicketPending, newTicketReason) {
        const {isLoading} = this.props;
        const buttonDisabled = !newTicketReason || newTicketReason === 'none' || newTicketPending;

        return (
            <div aria-busy={isLoading}>
                {isLoading ? (
                    <CircularProgress
                        size={50}
                        aria-label={intl.get('ariaLabelCircularProgress')}
                        aria-valuemin={0}
                        aria-valuemax={1}
                    />
                ) : (
                    <div aria-live="polite">
                        <FormControl fullWidth={true}>
                            <FormControl style={{margin: '0.35em auto 1em auto'}} fullWidth={true}>
                                <InputLabel htmlFor="select-reason" id="label-new-ticket-reason">
                                    {intl.get('reason')}
                                </InputLabel>
                                <Select
                                    value={newTicketReason}
                                    onChange={event => this.handleNewTicketReasonChange(event)}
                                    SelectDisplayProps={{
                                        'aria-labelledby': 'label-new-ticket-reason',
                                        id: 'select-reason',
                                        role: 'group',
                                    }}>
                                    {this.newTicketReasons.map((reason, i) => (
                                        <MenuItem key={i} value={reason.key}>
                                            {reason.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => this.onBtnCreateNewClick()}
                                disabled={buttonDisabled}>
                                {intl.get('createCase')}
                            </Button>
                        </FormControl>
                    </div>
                )}
            </div>
        );
    }

    render() {
        const {ticket, newTicketReason, newTicketPending, user} = this.props;
        const displayTicket = newTicketPending || ticket || {};
        const fraudCaseStatusText = getFraudCaseStatusDisplayText();
        const fraudCaseReasonText = getFraudCaseReasonDisplayText();
        const {
            caseId,
            type: caseType,
            status,
            assignee,
            caseUrl,
            licenseId,
            pseudonymId,
            userEventId,
            date,
            reasonCode,
            idDateTimes,
        } = displayTicket;

        const shouldShowCreateNewTicket =
            typeof user !== 'undefined' &&
            user !== null &&
            user.verifiedMeUserId &&
            user.verifiedMeUserId.length > 0 &&
            !newTicketPending;

        return (
            <div className="currentTicket">
                <Typography
                    variant="h5"
                    gutterBottom={true}
                    component={ticket && ticket.caseId ? 'h1' : 'h2'}>
                    {shouldShowCreateNewTicket
                        ? intl.get('createNewCase')
                        : intl.get('currentCase')}
                </Typography>
                {shouldShowCreateNewTicket ? (
                    this.renderCreateNewTicket(newTicketPending, newTicketReason)
                ) : (
                    <div>
                        {this.renderKeyValuePair(
                            intl.get('caseId') + ':',
                            caseUrl ? <CaseIdAnchor href={caseUrl} text={caseId} /> : caseId || '-',
                            newTicketPending && (
                                <span className="new-ticket-label">{intl.get('new')}</span>
                            )
                        )}
                        {caseType && this.renderKeyValuePair(intl.get('type') + ':', caseType)}
                        {this.renderKeyValuePair(
                            intl.get('reasonCode') + ':',
                            fraudCaseReasonText[reasonCode] || reasonCode || '-'
                        )}
                        {this.renderKeyValuePair(
                            intl.get('status') + ':',
                            fraudCaseStatusText[status] || status || '-'
                        )}
                        {this.renderKeyValuePair(intl.get('assignee') + ':', assignee || '-')}
                        {licenseId &&
                            this.renderKeyValuePair(intl.get('licenseId') + ':', licenseId)}
                        {pseudonymId &&
                            this.renderKeyValuePair(intl.get('pseudonymId') + ':', pseudonymId)}
                        {(userEventId || date) &&
                            this.renderKeyValuePair(
                                intl.get('transactionCode') + ':',
                                `${userEventId || intl.get('n/a')} (${getFormattedTime(date)})`
                            )}
                        {idDateTimes &&
                            idDateTimes.length > 0 &&
                            this.renderKeyValuePair(
                                intl.get('listOfDates') + ':',
                                this.renderDapListOfDates(idDateTimes)
                            )}
                    </div>
                )}
            </div>
        );
    }
}

export default CurrentTicket;

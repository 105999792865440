const ErrorCode = {
    SystemError: 'systemError',
    InvalidRequest: 'invalidRequest',
    ConfigurationError: 'configurationError',
    MissingUserContext: 'missingUserContext',
    InvalidUserContext: 'invalidUserContext',
    ExpiredUserContext: 'expiredUserContext',
    InvalidVerifiedMeUser: 'invalidVerifiedMeUser',
    CaseManagementLoginRequired: 'caseManagementLoginRequired',
    InvalidLockboxState: 'invalidLockboxState',
    InvalidCaseManagementAuth: 'invalidCaseManagementAuth',
    InvalidCaseManagementUser: 'invalidCaseManagementUser',
    InvalidSession: 'invalidSession',
    InvalidCase: 'invalidCase',
    IncorrectRole: 'incorrectRole',
    userNotFound: 'userNotFound',
    InvalidSAMLResponse: 'invalidSamlResponse',
    InvalidUser: 'invalidUser', //400
    NotFound: 'notFound',
    NoFraudReport: 'noFraudReport',
    TransactionsRequired: 'transactionsRequired',
    TransactionsNotAllowed: 'transactionsNotAllowed',
    InvalidReasonCode: 'invalidReasonCode',
    AbnormalUserAccount: 'abnormalUserAccount',
};

export default Object.freeze(ErrorCode);

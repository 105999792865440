import {applyMiddleware, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';

import reducer from './reducers';

export const configureStore = () => {
    const middlewares = [promiseMiddleware(), thunk];

    // use logger middleware only in development mode
    if (process.env.NODE_ENV === 'development') {
        const logger = createLogger();
        middlewares.push(logger);
    }

    return createStore(reducer, applyMiddleware(...middlewares));
};

export default configureStore;

import React from 'react';
import './ButtonBar.css';
import Button from '@material-ui/core/Button';

const btnStyle = {
    minWidth: 200,
};

class ButtonBar extends React.Component {
    render() {
        const {
            cancelTxt,
            cancelFn,
            cancelDisabled,
            submitTxt,
            submitFn,
            submitDisabled,
        } = this.props;

        return (
            <div className="buttonBar">
                <Button
                    variant="contained"
                    onClick={cancelFn}
                    style={{...btnStyle}}
                    disabled={cancelDisabled}>
                    {cancelTxt}
                </Button>

                <p>{this.props.note}</p>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={submitFn}
                    style={{...btnStyle}}
                    disabled={submitDisabled}>
                    {submitTxt}
                </Button>
            </div>
        );
    }
}

export default ButtonBar;

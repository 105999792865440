const defaultState = () => ({
    isOpen: false,
    text: '',
    vertical: 'top',
    horizontal: 'right',
    autoHideDuration: 6000,
    variant: 'success',
});

export default function reducer(state, action) {
    if (!state) {
        return defaultState();
    }

    switch (action.type) {
        case 'SHOW_NOTIFICATION':
            return {
                ...state,
                isOpen: true,
                ...action.payload,
            };
        case 'HIDE_NOTIFICATION':
            return {
                ...state,
                isOpen: false,
                text: '',
            };
        default:
    }

    return state;
}

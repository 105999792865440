import React from 'react';
import intl from 'react-intl-universal';
import './AlertDialog.css';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

class AlertDialog extends React.Component {
    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    renderTextField() {
        const {inputHelperText, error} = this.props;
        const {handleInput} = this.props;
        return (
            <TextField
                autoFocus
                margin="dense"
                id="coreId"
                label={inputHelperText}
                type="text"
                fullWidth
                error={error}
                onChange={event => handleInput(event)}
            />
        );
    }

    getDialogContentIds() {
        const {descriptions} = this.props;
        let ariaDescribedByString = '';
        for (let i = 0; i < descriptions.length; i++) {
            ariaDescribedByString = ariaDescribedByString.concat(
                `alert-dialog-description-${i.toString()}`,
                ' '
            );
        }
        return ariaDescribedByString;
    }

    render() {
        const {title, descriptions, open, withField, buttonDisabled} = this.props;
        const {handleCancel, handleConfirm} = this.props;
        return (
            <div>
                <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby={this.getDialogContentIds()}
                    PaperProps={{style: {padding: 25}}}>
                    <DialogTitle id="alert-dialog-title" disableTypography={true}>
                        <Typography variant="h6" gutterBottom={true} component="h3">
                            {title}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        {descriptions.map((d, i) => (
                            <DialogContentText
                                key={i}
                                id={`alert-dialog-description-${i}`}
                                style={{marginBottom: 20, color: 'black'}}>
                                {d}
                            </DialogContentText>
                        ))}
                        {withField && this.renderTextField()}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel} color="primary">
                            {intl.get('cancel')}
                        </Button>
                        <Button
                            onClick={handleConfirm}
                            color="primary"
                            disabled={buttonDisabled}
                            autoFocus>
                            {intl.get('ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default AlertDialog;

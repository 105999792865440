import {getBrowserLocale} from '../locales/intl';

import * as commonApi from '../api/common';

export const fetchReferenceData = () => {
    const currentBrowserLocale = getBrowserLocale();
    // Break locales like 'fr-CA' down to just the 2 first charactes (fr-CA -> fr)
    return {
        type: 'FETCH_REFERENCE_DATA',
        payload: commonApi.getReferenceData(
            currentBrowserLocale.length > 2
                ? currentBrowserLocale.substr(0, 2)
                : currentBrowserLocale
        ),
    };
};

import React from 'react';
import intl from 'react-intl-universal';
import {compose} from 'redux';

import moment from 'moment';

import UserActivityTransactionTable from '../../components/UserActivityTransactionTable';

import {
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    CircularProgress,
} from '@material-ui/core';

import './UserActivity.css';
import DateRange from '../../components/DateRange';
import EditableChipList from '../../components/EditableChipList';
import AlertDialog from '../../components/AlertDialog';
import {showNotification} from '../../actions/notificationActions';
import {connect} from 'react-redux';
import {withAuthorization} from '../../hocomponents/WithAuthorization';

class UserActivity extends React.Component {
    constructor(props) {
        super(props);
        this.alertDialogTitle = intl.get('queryUserTransactions');
        this.state = {
            lookUpTxnsBy: 'date-range',
            openAlertDialog: false,
            dateRange: {
                from: null,
                to: null,
            },
            transactionIds: [],
        };
    }

    handleRadioGroupChange = event =>
        this.setState({...this.state, lookUpTxnsBy: event.target.value});

    showAlertDialog = () => {
        const {dateRange, transactionIds, lookUpTxnsBy} = this.state;
        if (lookUpTxnsBy === 'date-range') {
            // date from is missing
            if (!dateRange.from) {
                this.props.dispatch(showNotification(intl.get('dateFromMissing'), 'warning'));
                return;
            }
            // date to is missing
            if (!dateRange.to) {
                this.props.dispatch(showNotification(intl.get('dateToMissing'), 'warning'));
                return;
            }
            // date from can't be greater than date to
            if (moment(dateRange.from).isAfter(moment(dateRange.to))) {
                this.props.dispatch(showNotification(intl.get('dateFromGreaterThanTo'), 'warning'));
                return;
            }
        } else if (lookUpTxnsBy === 'txn-list') {
            if (transactionIds.length === 0) {
                this.props.dispatch(showNotification(intl.get('transactionListEmpty'), 'warning'));
                return;
            }
        }

        this.setState({openAlertDialog: true});
    };

    handleAlertDialogCancel() {
        this.setState({openAlertDialog: false});
    }

    handleAlertDialogConfirm() {
        const {lookUpTxnsBy, dateRange, transactionIds} = this.state;

        let searchCriteria =
            lookUpTxnsBy === 'date-range'
                ? // Without this one second deduction below, it will show the date after the selected date.
                  {dateRange: {...dateRange, to: dateRange.to + 60 * 60 * 24 - 1}}
                : {transactionIds: transactionIds};
        this.props.fetchTransactions(searchCriteria);

        this.setState({openAlertDialog: false});
    }

    handleDateFromChange = d => {
        this.setState(prevState => ({
            dateRange: {
                ...prevState.dateRange,
                from: d,
            },
        }));
    };

    handleDateToChange = d => {
        this.setState(prevState => ({
            dateRange: {
                ...prevState.dateRange,
                to: d,
            },
        }));
    };

    handleTransactionIdsChanged = txnIds => {
        this.setState({
            transactionIds: txnIds,
        });
    };

    renderRadioGroup() {
        return (
            <RadioGroup
                value={this.state.lookUpTxnsBy}
                onChange={this.handleRadioGroupChange}
                style={{flexDirection: 'row'}}
                role="group"
                aria-label={intl.get('ariaLabelUserActivityQueryRadioGroup')}>
                <FormControlLabel
                    value={'date-range'}
                    control={<Radio color="primary" />}
                    label={intl.get('dateRange')}
                />
                <FormControlLabel
                    value={'txn-list'}
                    control={<Radio color="primary" />}
                    label={intl.get('transactionList')}
                />
            </RadioGroup>
        );
    }

    renderUserActivityTransactionTable(txns) {
        return (
            <UserActivityTransactionTable
                transactions={txns}
                handleChangeTransactionsMarked={this.props.handleChangeTransactionsMarked}
            />
        );
    }

    renderByDateRangeTransactionLookUp(ticketDate) {
        const {openAlertDialog, dateRange} = this.state;

        let dateRangeDefaults = {};
        if (ticketDate) {
            dateRangeDefaults.defaultFromDate = moment
                .unix(ticketDate)
                .subtract(10, 'days')
                .toDate();
            dateRangeDefaults.defaultToDate = moment
                .unix(ticketDate)
                .add(10, 'days')
                .toDate();
        } else {
            dateRangeDefaults.defaultFromDate = dateRange.from
                ? moment.unix(dateRange.from).toDate()
                : null;
            dateRangeDefaults.defaultToDate = dateRange.to
                ? moment.unix(dateRange.to).toDate()
                : null;
        }

        return (
            <React.Fragment>
                <p>{intl.get('dateRangePrompt')}:</p>
                <div className="row">
                    <DateRange
                        handleDateFromChange={this.handleDateFromChange}
                        handleDateToChange={this.handleDateToChange}
                        {...dateRangeDefaults}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{minWidth: 200}}
                        onClick={() => this.showAlertDialog()}
                        disabled={!dateRange.from || !dateRange.to}>
                        {intl.get('viewTransactions')}
                    </Button>
                    <AlertDialog
                        open={openAlertDialog}
                        handleCancel={this.handleAlertDialogCancel.bind(this)}
                        handleConfirm={this.handleAlertDialogConfirm.bind(this)}
                        title={this.alertDialogTitle}
                        descriptions={[
                            intl.get('queryUserTransactionsInst1'),
                            intl.get('queryUserTransactionsInst2'),
                        ]}
                    />
                </div>
            </React.Fragment>
        );
    }

    renderByListTransactionLookUp() {
        const {openAlertDialog, transactionIds} = this.state;
        return (
            <div style={{marginBottom: 10}}>
                <p>{intl.get('transactionCodePrompt')}:</p>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        margin: '21px 0 1px 0',
                        justifyContent: 'flex-end',
                    }}>
                    <EditableChipList
                        handleChipsChanged={this.handleTransactionIdsChanged.bind(this)}
                    />
                    <Button
                        style={{minWidth: 200, alignSelf: 'flex-end'}}
                        variant="outlined"
                        color="primary"
                        onClick={() => this.showAlertDialog()}
                        disabled={!transactionIds || !transactionIds.length}>
                        {intl.get('viewTransactions')}
                    </Button>
                    <AlertDialog
                        open={openAlertDialog}
                        handleCancel={this.handleAlertDialogCancel.bind(this)}
                        handleConfirm={this.handleAlertDialogConfirm.bind(this)}
                        title={this.alertDialogTitle}
                        descriptions={[intl.get('queryUserTransactionsInst1')]}
                    />
                </div>
            </div>
        );
    }

    render() {
        const {lookUpTxnsBy} = this.state;
        const {fetchedTransactions, isLoading, ticket} = this.props;
        const displayTicket = ticket || {};
        const {date} = displayTicket;

        return (
            <div className="userActivity">
                <div className="title-radio-row">
                    <Typography
                        variant="h5"
                        gutterBottom={true}
                        component="h3"
                        id="title-user-activity">
                        {intl.get('userActivity')}
                    </Typography>
                    {this.renderRadioGroup()}
                </div>
                <div className="container">
                    {lookUpTxnsBy === 'date-range' && this.renderByDateRangeTransactionLookUp(date)}
                    {lookUpTxnsBy === 'txn-list' && this.renderByListTransactionLookUp()}
                    <div aria-live="polite" aria-busy={isLoading}>
                        {isLoading ? (
                            <CircularProgress
                                size={50}
                                aria-label={intl.get('ariaLabelCircularProgress')}
                                style={{marginTop: '10px'}}
                                aria-valuemin={0}
                                aria-valuemax={1}
                            />
                        ) : (
                            fetchedTransactions.length > 0 &&
                            this.renderUserActivityTransactionTable(fetchedTransactions)
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    notificationState: state.notification,
});

const enhance = compose(
    withAuthorization(['fraud']),
    connect(mapStateToProps)
);

export default enhance(UserActivity);

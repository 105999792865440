import React from 'react';
import {connect} from 'react-redux';

/**
 * Higher Order Component that receives a wrapped component and
 * the user roles that are authorized to view the wrapped component.
 *
 * It receives the role of current logged in user from redux.
 * If the current user's role doesn't match any of the roles in
 * `allowed` roles this HOC returns nothing (null), otherwise it
 * renders the wrapped component.
 *
 * @param {*} allowedRoles The allowed roles (i.e. {fraud, lockbox, proofs})
 * that can view the WrappedComponent.
 */
export const withAuthorization = (allowedRoles = []) => {
    return WrappedComponent => {
        @connect(store => ({
            roles: store.auth.roles,
        }))
        class WithAuthorization extends React.Component {
            render() {
                const {roles} = this.props;
                const validRoles = roles.filter(role => allowedRoles.includes(role));
                const isAllowed = validRoles.length > 0;
                return isAllowed ? <WrappedComponent {...this.props} /> : null;
            }
        }

        return WithAuthorization;
    };
};

import React from 'react';
import intl from 'react-intl-universal';
import queryString from 'query-string';
import './JiraTokenPage.css';
import vmeLogo from '../../../images/interac-logo.svg';
import jiraLogo from '../../../images/jira-logo.png';
import {Paper, Button} from '@material-ui/core';

export default class JiraTokenPage extends React.Component {
    componentDidMount() {
        document.title = intl.get('jiraTokenPageName').d('DLBP Support Tool - Jira Authentication');
    }

    render() {
        const {pageName, jiraInvalidUser} = this.props;
        const params = queryString.parse(window.location.search);
        const {oauth_token, oauth_verifier} = params;
        const linkCaseManagement = '/linkCaseManagement';

        if (oauth_token && oauth_verifier) {
            // In JIRA OAuth flow, need to to do POST
            window.location.href =
                linkCaseManagement +
                '?oauth_token=' +
                oauth_token +
                '&oauthVerifier=' +
                oauth_verifier;
            return;
        }

        return (
            <div className="dialogContainer">
                <Paper className="jiraTokenPage" square elevation={10}>
                    <div className="token" role="banner">
                        <div className="vmeLogoBg">
                            <img src={vmeLogo} alt={intl.get('vmeLogo')} width="50" height="50" />
                        </div>
                        <div className="pageName">
                            <h1 style={{fontSize: '1em'}}>{pageName}</h1>
                        </div>
                    </div>
                    <div className="jiraPageContent" role="main">
                        {jiraInvalidUser ? (
                            <div>
                                <div className="error">{intl.get('jiraInvalidUserPrompt')}</div>
                                <a
                                    href="/jiraLogout"
                                    target="_blank"
                                    aria-label={intl.get('ariaLabelLinkNewWindow', {
                                        linkDesc: intl.get('jiraLogoutLink'),
                                    })}>
                                    {intl.get('jiraLogoutLink')}
                                </a>
                            </div>
                        ) : (
                            <div className="directionText">{intl.get('jiraAccessTokenPrompt')}</div>
                        )}
                        <div className="jiraLogoContainer">
                            <img src={jiraLogo} alt={intl.get('jiraLogo')} width="160" />
                        </div>
                        <Button
                            className="signInButton"
                            variant="contained"
                            color="primary"
                            aria-label={intl.get('ariaLabelSignInBtn')}
                            onClick={() => (window.location.href = linkCaseManagement)}>
                            {intl.get('signin')}
                        </Button>
                        <p className="signInNote">{intl.get('jiraRedirectPrompt')}</p>
                    </div>
                </Paper>
            </div>
        );
    }
}

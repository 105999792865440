const LockboxStatus = {
    Active: 'ACTIVE',
    OnHold: 'ON_HOLD',
    Suspended: 'SUSPENDED',
    Revoked: 'REVOKED',
    // call center agent on hold
    Unavailable: 'UNAVAILABLE',
};

export default Object.freeze(LockboxStatus);

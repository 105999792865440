import React from 'react';
import intl from 'react-intl-universal';
import './UserActiveTickets.css';

import {
    Typography,
    Table,
    TableCell,
    TableHead,
    TableBody,
    TableRow,
    TablePagination,
    CircularProgress,
    TableFooter,
} from '@material-ui/core';

import {withAuthorization} from '../../hocomponents/WithAuthorization';

import CaseIdAnchor from '../../components/CaseIdAnchor';
import {getFraudCaseStatusDisplayText} from '../../enumerations/FraudCaseStatus';
import {getFormattedTime} from '../../utils';

class UserActiveTickets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
        };
    }

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    render() {
        const {user, tickets, idleText, searchError, isLoading} = this.props;
        const {rowsPerPage, page} = this.state;
        const fraudCaseStatusText = getFraudCaseStatusDisplayText();

        if (searchError) {
            // no user found
            return (
                <div style={{padding: 25}} className="userActiveTickets error">
                    {intl.get('noUserTryAgain')}
                </div>
            );
        } else if (!user || !user.verifiedMeUserId || user.verifiedMeUserId.length === 0) {
            // no user loaded
            return (
                <div style={{padding: 25}} className="userActiveTickets">
                    {idleText}
                </div>
            );
        }

        return (
            <div className="userActiveTickets" aria-busy={isLoading}>
                <Typography
                    variant="h5"
                    gutterBottom={true}
                    component="h2"
                    id="title-user-active-tickets">
                    {intl.get('casesForThisUser')}
                </Typography>
                {isLoading ? (
                    <CircularProgress
                        size={50}
                        aria-label={intl.get('ariaLabelCircularProgress')}
                        aria-valuemin={0}
                        aria-valuemax={1}
                    />
                ) : (
                    <div aria-live="polite">
                        {tickets.length === 0 ? (
                            <p>{intl.get('noActiveCases')}</p>
                        ) : (
                            <div>
                                <Table
                                    tabIndex={0}
                                    aria-labelledby="title-user-active-tickets"
                                    summary={intl.get('tableSummaryCasesForThisUser')}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                aria-label={intl.get('ariaLabelColumnCaseId')}
                                                className="head-cell">
                                                {intl.get('caseId')}
                                            </TableCell>
                                            <TableCell
                                                aria-label={intl.get('ariaLabelColumnType')}
                                                className="head-cell">
                                                {intl.get('type')}
                                            </TableCell>
                                            <TableCell
                                                aria-label={intl.get('ariaLabelColumnCreatedTime')}
                                                className="head-cell">
                                                {intl.get('dateReported')}
                                            </TableCell>
                                            <TableCell
                                                aria-label={intl.get(
                                                    'ariaLabelColumnCreatedAssignee'
                                                )}
                                                className="head-cell">
                                                {intl.get('assignee')}
                                            </TableCell>
                                            <TableCell
                                                aria-label={intl.get('ariaLabelColumnStatus')}
                                                className="head-cell">
                                                {intl.get('status')}
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tickets
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((t, i) => (
                                                <TableRow
                                                    hover
                                                    tabIndex={0}
                                                    className="table-row"
                                                    aria-label={intl.get(
                                                        'ariaLabelActiveCasesRowClick',
                                                        {caseId: t.caseId}
                                                    )}
                                                    key={i}
                                                    onClick={() => this.props.onTicketSelection(t)}
                                                    onKeyPress={evt => {
                                                        switch (evt.key) {
                                                            case 'Enter':
                                                            case ' ':
                                                                this.props.onTicketSelection(t);
                                                                break;
                                                            default:
                                                        }
                                                    }}>
                                                    <TableCell className="row-cell">
                                                        <CaseIdAnchor
                                                            href={t.caseUrl}
                                                            text={t.caseId}
                                                        />
                                                    </TableCell>
                                                    <TableCell className="row-cell">
                                                        {t.type}
                                                    </TableCell>
                                                    <TableCell className="row-cell">
                                                        {getFormattedTime(t.createdTime)}
                                                    </TableCell>
                                                    <TableCell className="row-cell">
                                                        {t.assignee}
                                                    </TableCell>
                                                    <TableCell className="row-cell">
                                                        {fraudCaseStatusText[t.status] || t.status}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                labelDisplayedRows={({from, to, count}) =>
                                                    intl.get('paginationTemplate', {
                                                        from: from,
                                                        to: to,
                                                        count: count,
                                                    })
                                                }
                                                count={tickets.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                rowsPerPageOptions={[5, 10, 15, 20]}
                                                backIconButtonProps={{
                                                    'aria-label': intl.get('previousPage'),
                                                }}
                                                nextIconButtonProps={{
                                                    'aria-label': intl.get('nextPage'),
                                                }}
                                                onChangePage={this.handleChangePage}
                                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                SelectProps={{
                                                    SelectDisplayProps: {
                                                        'aria-label': `${intl.get(
                                                            'rowsPerPage'
                                                        )} ${rowsPerPage}`,
                                                        role: 'group',
                                                    },
                                                }}
                                                aria-label={intl.get('ariaLabelTablePagination')}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default withAuthorization(['fraud', 'proofs'])(UserActiveTickets);

import React from 'react';
import intl from 'react-intl-universal';
import {withRouter, Link} from 'react-router-dom';
import './SideBar.css';
import vmeLogo from '../../../images/interac-logo.svg';

import {isCallCenterAgent, isLegalAgent} from '../../reducers/authReducer';

class SideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 'active-cases',
        };
    }

    handlePageChange = pageName => {
        this.setState({page: pageName});
        this.props.onPageChange(pageName);
    };

    render() {
        const {location, roles} = this.props;

        return (
            <nav className="sideBar">
                <a href="#main-content" tabIndex={1} className="skip-to-content">
                    {intl.get('skipToContent')}
                </a>
                <div className="vmeLogoBg">
                    <a href="/" tabIndex={2}>
                        <img src={vmeLogo} alt={intl.get('vmeLogo')} width="50" height="50" />
                    </a>
                </div>

                {!isCallCenterAgent(roles) && (
                    <Link to="/active-cases" tabIndex={6}>
                        <div
                            className={
                                'tab activeCasesTab' +
                                (location.pathname.startsWith('/active-cases') ||
                                location.pathname === '/'
                                    ? '-selected'
                                    : '')
                            }>
                            {intl.get('activeCases')}
                        </div>
                    </Link>
                )}
                {!isLegalAgent(roles) && (
                    <Link to="/find-user" tabIndex={7}>
                        <div
                            className={
                                'tab findUsersTab' +
                                (location.pathname.startsWith('/find-user') ? '-selected' : '')
                            }>
                            {intl.get('findUser')}
                        </div>
                    </Link>
                )}
            </nav>
        );
    }
}

export default withRouter(SideBar);

import intl from 'react-intl-universal';

export const FraudCaseReason = {
    syntheticId: 'Synthetic ID',
    unauthorizedTransactions: 'Unauthorized Transactions',
    fraudAlert: 'Fraud Alert',
};

export default Object.freeze(FraudCaseReason);

export const getFraudCaseReasonDisplayText = function() {
    return {
        syntheticId: intl.get('syntheticId'),
        unauthorizedTransactions: intl.get('unauthorizedTransactions'),
        fraudAlert: intl.get('fraudAlert'),
    };
};

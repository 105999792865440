export const showNotification = (text, variant) => ({
    type: 'SHOW_NOTIFICATION',
    payload: {
        text: text,
        variant: variant,
    },
});

export const hideNotification = () => ({
    type: 'HIDE_NOTIFICATION',
});

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/components/App';

import {BrowserRouter as Router} from 'react-router-dom';

import {Provider} from 'react-redux';
import configureStore from './app/store';
import {setLocale} from './app/locales/intl';

import registerHttpInterceptors from './scripts/registerHttpInterceptors';

const store = configureStore();
registerHttpInterceptors(store);

setLocale().then(() => {
    // Render our root component into the div with id "root"
    ReactDOM.render(
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>,
        document.getElementById('root')
    );
});

import {retrieveCase} from '../api/common';

const defaultState = () => {
    return {
        userTransactions: [],
        markedTransactions: [],
        fetchingTransactions: false,
    };
};

export default function reducer(state, action) {
    if (!state) {
        return defaultState();
    }
    switch (action.type) {
        case 'FETCH_TRANSACTIONS_FULFILLED':
            return {
                ...state,
                fetchingTransactions: false,
                userTransactions: action.payload.transactions || [],
            };
        case 'FETCH_TRANSACTIONS_PENDING':
            return {
                ...state,
                fetchingTransactions: true,
            };
        case 'FETCH_TRANSACTIONS_REJECTED':
            return {
                ...state,
                fetchingTransactions: false,
            };

        case 'CLEAR_USER_TRANSACTIONS':
            return {
                ...state,
                userTransactions: [],
            };
        case 'SET_MARKED_TRANSACTIONS':
            return {
                ...state,
                markedTransactions: action.payload,
            };
        case 'CLEAR_MARKED_TRANSACTIONS':
            return {
                ...state,
                markedTransactions: [],
            };
        default:
    }
    return state;
}

// create a promises that populates fraud case URL for all applicable transactions/lockboxes.
// here we use await to avoid previous request being cancelled by the same cancellation token.
export const populateCaseUrl = async txns => {
    let ret = [];

    for (let txn of txns) {
        if (txn && txn.fraudCaseId) {
            try {
                let caseInfo = await retrieveCase(txn.fraudCaseId);
                txn = {...txn, fraudCaseId: caseInfo.caseId, fraudCaseUrl: caseInfo.caseUrl};
            } catch (e) {
                console.error('failed to call retrieveCase():', e);
            }
        }

        ret.push(txn);
    }

    return ret;
};

import axios from 'axios';
import intl from 'react-intl-universal';
import ErrorCode from '../app/enumerations/ErrorCode';
import {showNotification} from '../app/actions/notificationActions';
import {resetSessionTimer} from '../app/actions/sessionTimerActions';

const getCookie = cname => {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

export default function register(store) {
    // Intercept all responses and check for `error` object
    // inside the body.
    // If `error` is present, display an error dialog and reject
    // the promise

    axios.interceptors.response.use(
        response => {
            store.dispatch(resetSessionTimer(store.getState().auth.sessionTimeoutWarningSeconds));
            return response.data;
        },
        error => {
            store.dispatch(resetSessionTimer(store.getState().auth.sessionTimeoutWarningSeconds));

            if (axios.isCancel(error)) {
                return Promise.reject(error);
            }

            const {error: apiError} = error.response.data;

            if (!apiError) {
                store.dispatch(showNotification(intl.get('interceptorMsgApiError'), 'error'));
                return;
            }

            const {code, correlationId, description} = apiError;

            let message = `[${correlationId}] ${code}: ${description}`;
            let notificationVariant = 'error';

            switch (code) {
                case ErrorCode.IncorrectRole:
                    message = intl.get('interceptorMsgNoPermission', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.ExpiredUserContext:
                case ErrorCode.MissingUserContext:
                case ErrorCode.InvalidUserContext:
                case ErrorCode.InvalidSession:
                    message = intl.get('interceptorMsgInvalidSession');
                    notificationVariant = 'warning';
                    setTimeout(() => {
                        window.location.assign('/landing');
                    }, 5 * 1000);
                    break;
                case ErrorCode.CaseManagementLoginRequired:
                    message = intl.get('interceptorMsgLoginRequired');
                    notificationVariant = 'warning';
                    setTimeout(() => {
                        window.location.assign('/jira');
                    }, 5 * 1000);
                    break;
                // System Errors (500)
                case ErrorCode.SystemError:
                case ErrorCode.ConfigurationError:
                    message = intl.get('interceptorMsgConfigurationError', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.InvalidRequest:
                    message = intl.get('interceptorMsgInvalidRequest', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.InvalidVerifiedMeUser:
                    message = intl.get('interceptorMsgInvalidVmeUser', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.InvalidLockboxState:
                    message = intl.get('interceptorMsgInvalidLockboxState', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.InvalidCaseManagementAuth:
                    message = intl.get('interceptorMsgInvalidAuth', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.InvalidCaseManagementUser:
                    message = intl.get('interceptorMsgInvalidCaseManagementUser', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.InvalidCase:
                    message = intl.get('interceptorMsgInvalidCase', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.userNotFound:
                    message = intl.get('interceptorMsgUserNotFound', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.InvalidSAMLResponse:
                    message = intl.get('interceptorMsgInvalidSamlResponse', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.InvalidUser:
                    message = intl.get('interceptorMsgInvalidUser', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.NotFound:
                    message = intl.get('interceptorMsgNotFound', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.NoFraudReport:
                    message = intl.get('interceptorMsgNoFraudReport', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.TransactionsRequired:
                    message = intl.get('interceptorMsgTransactionsRequired', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.TransactionsNotAllowed:
                    message = intl.get('interceptorMsgTransactionsNotAllowed', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.InvalidReasonCode:
                    message = intl.get('interceptorMsgInvalidReasonCode', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                case ErrorCode.AbnormalUserAccount:
                    message = intl.get('interceptorMsgAbnormalUserAccount', {
                        correlationId: correlationId,
                        code: code,
                    });
                    break;
                default:
                    break;
            }

            store.dispatch(showNotification(message, notificationVariant));
            return Promise.reject(error.response);
        }
    );

    // Intercept all responses to get put CSRF cookie
    // in the headers
    axios.interceptors.request.use(
        config => {
            config.headers['X-Csrf-Token'] = getCookie('csrf_token');
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
}

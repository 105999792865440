import React from 'react';
import intl from 'react-intl-universal';
import './TransactionDetailsComponent.css';

import moment from 'moment';
import {withReferenceData} from '../../hocomponents/WithReferenceData';
import TxType from '../../enumerations/TransactionType';
import {getFormattedTime} from '../../utils'

import {ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class TransactionDetailComponent extends React.Component {
    renderTransactionDetails(data) {
        const txDetails = data.transactionDetails;
        const dlbpData = data.dlbpData;

        switch (data.type) {
            case TxType.DlbpSuspendUser:
            case TxType.DlbpResumeUser:
            case TxType.DlbpHoldUser:
            case TxType.DlbpUnholdUser:
            case TxType.DlbpDeleteLockbox:
                return (
                    <ExpansionPanel
                        role="group"
                        style={{boxShadow: 'none', background: 'none'}}
                        onClick={e => e.stopPropagation()}>
                        <ExpansionPanelSummary
                            aria-label={intl.get('ariaLabelExpansionPanel', {
                                txnId: data.userEventId,
                            })}
                            style={{padding: '0 24px 0 0'}}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dlbpAgent')}: </span>
                                    <span className="detail-value">
                                        {dlbpData ? dlbpData.userId : '-'}
                                    </span>
                                </div>
                                <div>
                                    <span className="detail-name">{intl.get('lockboxId')}: </span>
                                    <span className="detail-value">{data.lockboxID || '-'}</span>
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                    </ExpansionPanel>
                );

            case TxType.UserDeleteLockbox:
            case TxType.UserRecoverLockbox:
                return (
                    <ExpansionPanel
                        role="group"
                        style={{boxShadow: 'none', background: 'none'}}
                        onClick={e => e.stopPropagation()}>
                        <ExpansionPanelSummary
                            aria-label={intl.get('ariaLabelExpansionPanel', {
                                txnId: data.userEventId,
                            })}
                            style={{padding: '0 24px 0 0'}}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('lockboxId')}: </span>
                                    <span className="detail-value">{data.lockboxID || '-'}</span>
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                    </ExpansionPanel>
                );

            case TxType.UserClaimPseudonym:
            case TxType.UserRemovePseudonym:
            case TxType.UserRecoverPseudonym:
                // date, device, dap category
                return (
                    <ExpansionPanel
                        role="group"
                        style={{boxShadow: 'none', background: 'none'}}
                        onClick={e => e.stopPropagation()}>
                        <ExpansionPanelSummary
                            aria-label={intl.get('ariaLabelExpansionPanel', {
                                txnId: data.userEventId,
                            })}
                            style={{padding: '0 24px 0 0'}}
                            expandIcon={<ExpandMoreIcon />}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dapCategory')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor(
                                            'dapDisplayCategories',
                                            txDetails.dapDisplayCategoryId
                                        )}
                                    </span>
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{padding: '0 0 12px'}}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dapName')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor('members', txDetails.dapId)}
                                    </span>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );

            case TxType.UserCreateLockbox:
                // date, device, dlbp
                return (
                    <ExpansionPanel
                        role="group"
                        style={{boxShadow: 'none', background: 'none'}}
                        onClick={e => e.stopPropagation()}>
                        <ExpansionPanelSummary
                            aria-label={intl.get('ariaLabelExpansionPanel', {
                                txnId: data.userEventId,
                            })}
                            style={{padding: '0 24px 0 0'}}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dlbp')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor('members', txDetails.dlbpId)}
                                    </span>
                                </div>
                                <div>
                                    <span className="detail-name">{intl.get('tcVers')}: </span>
                                    <span className="detail-value">{`${
                                        txDetails.acceptedTerms.version
                                    } (${txDetails.acceptedTerms.locale})`}</span>
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                    </ExpansionPanel>
                );
                case TxType.DapUpdateAsset:
                return (
                    <ExpansionPanel
                        role="group"
                        style={{boxShadow: 'none', background: 'none'}}
                        onClick={e => e.stopPropagation()}>
                        <ExpansionPanelSummary
                            aria-label={intl.get('ariaLabelExpansionPanel', {
                                txnId: data.userEventId,
                            })}
                            style={{padding: '0 24px 0 0'}}
                            expandIcon={<ExpandMoreIcon />}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">
                                        {intl.get('assetId')}:{' '}
                                    </span>
                                    <span className="detail-value">
                                        {txDetails.digitalAssetId}
                                    </span>
                                </div>
                                <div>
                                    <span className="detail-name">
                                        {intl.get('expires')}:{' '}
                                    </span>
                                    <span className="detail-value">
                                        {txDetails.expires ? getFormattedTime(txDetails.expires): '-'}
                                    </span>
                                </div>
                                <div>
                                    <span className="detail-name">
                                        {intl.get('status')}:{' '}
                                    </span>
                                    <span className="detail-value">
                                        {txDetails.status}
                                    </span>
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                    </ExpansionPanel>
                );
            case TxType.UserCreateDA:
                return (
                    <ExpansionPanel
                        role="group"
                        style={{boxShadow: 'none', background: 'none'}}
                        onClick={e => e.stopPropagation()}>
                        <ExpansionPanelSummary
                            aria-label={intl.get('ariaLabelExpansionPanel', {
                                txnId: data.userEventId,
                            })}
                            style={{padding: '0 24px 0 0'}}
                            expandIcon={<ExpandMoreIcon />}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dapCategory')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor(
                                            'dapDisplayCategories',
                                            txDetails.dapDisplayCategoryId
                                        )}
                                    </span>
                                </div>
                                {txDetails.assets.map((asset, i) => (
                                    <div key={i}>
                                        <div>
                                            <span className="detail-name">
                                                {intl.get('assetId')}:{' '}
                                            </span>
                                            <span className="detail-value">
                                                {asset.digitalAssetId}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="detail-name">
                                                {intl.get('expires')}:{' '}
                                            </span>
                                            <span className="detail-value">
                                                {asset.expires ? getFormattedTime(asset.expires): '-'}
                                            </span>
                                        </div>
                                        <div>
                                            <span className="detail-name">
                                                {intl.get('status')}:{' '}
                                            </span>
                                            <span className="detail-value">
                                                {asset.status}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{padding: '0 0 12px'}}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dapName')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor('members', txDetails.dapId)}
                                    </span>
                                </div>
                                {txDetails.assets.map((asset, i) => (
                                    <div key={i}>
                                        <div>
                                            <span className="detail-name">
                                                {intl.get('type')}:{' '}
                                            </span>
                                            <span className="detail-value">
                                                {this.props.getDisplayNameFor(
                                                    'assetSchemas',
                                                    asset.assetType
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );

                case TxType.UserExecuteService:
                return (
                    <ExpansionPanel
                        role="group"
                        style={{boxShadow: 'none', background: 'none'}}
                        onClick={e => e.stopPropagation()}>
                        <ExpansionPanelSummary
                            aria-label={intl.get('ariaLabelExpansionPanel', {
                                txnId: data.userEventId,
                            })}
                            style={{padding: '0 24px 0 0'}}
                            expandIcon={<ExpandMoreIcon />}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dapCategory')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor(
                                            'dapDisplayCategories',
                                            txDetails.dapDisplayCategoryId
                                        )}
                                    </span>
                                </div>
                                <div>
                                    <span className="detail-name">
                                        {intl.get('serviceResponseId')} :{' '}
                                    </span>
                                    <span className="detail-value">
                                        {txDetails.serviceResponseId}
                                    </span>
                                </div>
                                <div>
                                    <span className="detail-name">{intl.get('licenseId')}: </span>
                                    <span className="detail-value">{txDetails.licenseId}</span>
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{padding: '0 0 12px'}}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dapName')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor('members', txDetails.dapId)}
                                    </span>
                                </div>
                                <div>
                                    <span className="detail-name">{intl.get('serviceName')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor(
                                            'serviceSchemas',
                                            txDetails.serviceName
                                        )}
                                    </span>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );

            case TxType.DacUseLicense:
                return (
                    <ExpansionPanel
                        role="group"
                        style={{boxShadow: 'none', background: 'none'}}
                        onClick={e => e.stopPropagation()}>
                        <ExpansionPanelSummary
                            aria-label={intl.get('ariaLabelExpansionPanel', {
                                txnId: data.userEventId,
                            })}
                            style={{padding: '0 24px 0 0'}}
                            expandIcon={<ExpandMoreIcon />}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dacCategory')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor(
                                            'dacDisplayCategories',
                                            txDetails.license.dacDisplayCategoryId
                                        )}
                                    </span>
                                </div>
                                <div>
                                    <span className="detail-name">{intl.get('licenseId')}: </span>
                                    <span className="detail-value">{txDetails.license.id}</span>
                                </div>
                                <div>
                                    <div className="detail-name">{intl.get('assets')}: </div>
                                    {txDetails.assets && txDetails.assets.length ? (
                                        txDetails.assets.map((asset, i) => {
                                            return (
                                                <div key={i}>
                                                    <ExpansionPanel
                                                        role="group"
                                                        style={{
                                                            boxShadow: 'none',
                                                            border: '1px solid #e0e0e0',
                                                            background: 'none',
                                                        }}
                                                        onClick={e => e.stopPropagation()}>
                                                        <ExpansionPanelSummary
                                                            aria-label={intl.get(
                                                                'ariaLabelExpansionPanelAssets',
                                                                {
                                                                    txnId: data.userEventId,
                                                                    txnType: intl.get(data.type),
                                                                }
                                                            )}
                                                            style={{padding: '0 10px'}}
                                                            expandIcon={<ExpandMoreIcon />}>
                                                            <div className="details-container">
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('assetId')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {asset.digitalAssetId}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('dapCategory')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'dapDisplayCategories',
                                                                            asset.dapDisplayCategoryId
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails
                                                            style={{padding: '0 10px'}}>
                                                            <div className="details-container">
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('dapName')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'members',
                                                                            asset.dapId
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('assetType')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'assetSchemas',
                                                                            asset.assetType
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('fieldNames')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {asset.fields
                                                                            .split(' ')
                                                                            .join(', ')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div>{intl.get('noAssets')}</div>
                                    )}
                                </div>
                                <div>
                                    <div className="detail-name">{intl.get('services')}: </div>
                                    {txDetails.services && txDetails.services.length ? (
                                        txDetails.services.map((service, i) => {
                                            return (
                                                <div key={i}>
                                                    <ExpansionPanel
                                                        role="group"
                                                        style={{
                                                            boxShadow: 'none',
                                                            border: '1px solid #e0e0e0',
                                                            background: 'none',
                                                        }}
                                                        onClick={e => e.stopPropagation()}>
                                                        <ExpansionPanelSummary
                                                            aria-label={intl.get(
                                                                'ariaLabelExpansionPanelServices',
                                                                {
                                                                    txnId: data.userEventId,
                                                                    txnType: intl.get(data.type),
                                                                }
                                                            )}
                                                            style={{padding: '0 10px'}}
                                                            expandIcon={<ExpandMoreIcon />}>
                                                            <div className="details-container">
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('dapCategory')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'dapDisplayCategories',
                                                                            service.dapDisplayCategoryId
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get(
                                                                            'serviceResponseId'
                                                                        )}
                                                                        :{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {service.serviceResponseId}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails
                                                            style={{padding: '0 10px'}}>
                                                            <div className="details-container">
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('dapName')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'members',
                                                                            service.dapId
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('serviceName')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'serviceSchemas',
                                                                            service.serviceName
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div>{intl.get('noServices')}</div>
                                    )}
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{padding: '0 0 12px'}}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dacName')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor(
                                            'members',
                                            txDetails.license.dacId
                                        )}
                                    </span>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );
            case TxType.UserIssueLicense:
                return (
                    <ExpansionPanel
                        role="group"
                        style={{boxShadow: 'none', background: 'none'}}
                        onClick={e => e.stopPropagation()}>
                        <ExpansionPanelSummary
                            aria-label={intl.get('ariaLabelExpansionPanel', {
                                txnId: data.userEventId,
                            })}
                            style={{padding: '0 24px 0 0'}}
                            expandIcon={<ExpandMoreIcon />}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dacCategory')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor(
                                            'dacDisplayCategories',
                                            txDetails.license.dacDisplayCategoryId
                                        )}
                                    </span>
                                </div>
                                <div>
                                    <span className="detail-name">{intl.get('licenseId')}: </span>
                                    <span className="detail-value">{txDetails.license.id}</span>
                                </div>
                                <div>
                                    <span className="detail-name">{intl.get('expires')}: </span>
                                    <span className="detail-value">
                                        {getFormattedTime(txDetails.license.expiry)}
                                    </span>
                                </div>
                                <div>
                                    <div className="detail-name">{intl.get('assets')}: </div>
                                    {txDetails.assets && txDetails.assets.length ? (
                                        txDetails.assets.map((asset, i) => {
                                            return (
                                                <div key={i}>
                                                    <ExpansionPanel
                                                        role="group"
                                                        style={{
                                                            boxShadow: 'none',
                                                            border: '1px solid #e0e0e0',
                                                            background: 'none',
                                                        }}
                                                        onClick={e => e.stopPropagation()}>
                                                        <ExpansionPanelSummary
                                                            aria-label={intl.get(
                                                                'ariaLabelExpansionPanelAssets',
                                                                {
                                                                    txnId: data.userEventId,
                                                                    txnType: intl.get(data.type),
                                                                }
                                                            )}
                                                            style={{padding: '0 10px'}}
                                                            expandIcon={<ExpandMoreIcon />}>
                                                            <div className="details-container">
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('assetId')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {asset.digitalAssetId}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('dapCategory')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'dapDisplayCategories',
                                                                            asset.dapDisplayCategoryId
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails
                                                            style={{padding: '0 10px'}}>
                                                            <div className="details-container">
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('dapName')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'members',
                                                                            asset.dapId
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('assetType')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'assetSchemas',
                                                                            asset.assetType
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('fieldNames')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {asset.fields
                                                                            .split(' ')
                                                                            .join(', ')}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div>{intl.get('noAssets')}</div>
                                    )}
                                </div>
                                <div>
                                    <div className="detail-name">{intl.get('services')}: </div>
                                    {txDetails.services && txDetails.services.length ? (
                                        txDetails.services.map((service, i) => {
                                            return (
                                                <div key={i}>
                                                    <ExpansionPanel
                                                        role="group"
                                                        style={{
                                                            boxShadow: 'none',
                                                            border: '1px solid #e0e0e0',
                                                            background: 'none',
                                                        }}
                                                        onClick={e => e.stopPropagation()}>
                                                        <ExpansionPanelSummary
                                                            aria-label={intl.get(
                                                                'ariaLabelExpansionPanelServices',
                                                                {
                                                                    txnId: data.userEventId,
                                                                    txnType: intl.get(data.type),
                                                                }
                                                            )}
                                                            style={{padding: '0 10px'}}
                                                            expandIcon={<ExpandMoreIcon />}>
                                                            <div className="details-container">
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('dapCategory')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'dapDisplayCategories',
                                                                            service.dapDisplayCategoryId
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get(
                                                                            'serviceResponseId'
                                                                        )}
                                                                        :{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {service.serviceResponseId}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ExpansionPanelSummary>
                                                        <ExpansionPanelDetails
                                                            style={{padding: '0 10px'}}>
                                                            <div className="details-container">
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('dapName')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'members',
                                                                            service.dapId
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="detail-name">
                                                                        {intl.get('dapCategory')}:{' '}
                                                                    </span>
                                                                    <span className="detail-value">
                                                                        {this.props.getDisplayNameFor(
                                                                            'serviceSchemas',
                                                                            service.serviceName
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </ExpansionPanelDetails>
                                                    </ExpansionPanel>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <div>{intl.get('noServices')}</div>
                                    )}
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{padding: '0 0 12px'}}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('dacName')}: </span>
                                    <span className="detail-value">
                                        {this.props.getDisplayNameFor(
                                            'members',
                                            txDetails.license.dacId
                                        )}
                                    </span>
                                </div>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                );

            case TxType.UserAcceptTerms:
                return (
                    <ExpansionPanel
                        role="group"
                        style={{boxShadow: 'none', background: 'none'}}
                        onClick={e => e.stopPropagation()}>
                        <ExpansionPanelSummary
                            aria-label={intl.get('ariaLabelExpansionPanel', {
                                txnId: data.userEventId,
                            })}
                            style={{padding: '0 24px 0 0'}}>
                            <div className="details-container">
                                <div>
                                    <span className="detail-name">{intl.get('tcVers')}: </span>
                                    <span className="detail-value">{`${txDetails.version} (${
                                        txDetails.locale
                                    })`}</span>
                                </div>
                            </div>
                        </ExpansionPanelSummary>
                    </ExpansionPanel>
                );

            case TxType.DlbpFraudSearch:
                if (txDetails.dateRange) {
                    return (
                        <ExpansionPanel
                            role="group"
                            style={{boxShadow: 'none', background: 'none'}}
                            onClick={e => e.stopPropagation()}>
                            <ExpansionPanelSummary
                                aria-label={intl.get('ariaLabelExpansionPanel', {
                                    txnId: data.userEventId,
                                })}
                                style={{padding: '0 24px 0 0'}}>
                                <div className="details-container">
                                    <div>
                                        <span className="detail-name">
                                            {intl.get('dlbpAgent')}:{' '}
                                        </span>
                                        <span className="detail-value">
                                            {dlbpData ? dlbpData.userId : '-'}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="detail-name">
                                            {intl.get('dateRange')}:{' '}
                                        </span>
                                        <span className="detail-value">{`${moment
                                            .unix(txDetails.dateRange.from)
                                            .format('MM/DD/YYYY')} - ${moment
                                            .unix(txDetails.dateRange.to)
                                            .format('MM/DD/YYYY')}`}</span>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                        </ExpansionPanel>
                    );
                } else if (txDetails.transactions) {
                    return (
                        <ExpansionPanel
                            role="group"
                            style={{boxShadow: 'none', background: 'none'}}
                            onClick={e => e.stopPropagation()}>
                            <ExpansionPanelSummary
                                aria-label={intl.get('ariaLabelExpansionPanel', {
                                    txnId: data.userEventId,
                                })}
                                style={{padding: '0 24px 0 0'}}>
                                <div className="details-container">
                                    <div>
                                        <span className="detail-name">
                                            {intl.get('dlbpAgent')}:{' '}
                                        </span>
                                        <span className="detail-value">
                                            {dlbpData ? dlbpData.userId : '-'}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="detail-name">
                                            {intl.get('transactionList')}:{' '}
                                        </span>
                                        <span className="detail-value">
                                            {txDetails.transactions
                                                .map(tx => tx.userEventId)
                                                .join(', ')}
                                        </span>
                                    </div>
                                </div>
                            </ExpansionPanelSummary>
                        </ExpansionPanel>
                    );
                }
                break;

            // currently not captured
            case TxType.UserViewAssetForLicense:
            case TxType.UserRetrieveLicenseRequest:
                return '-';

            // nothing to display
            case TxType.UserCreatePseudonym:
            case TxType.UserRetrieveDeviceSecurityData:
                return '-';

            default:
                return '-';
        }
    }

    render() {
        const {ariaLabel} = this.props;
        return (
            <div className="txDetails" aria-label={ariaLabel}>
                {this.renderTransactionDetails(this.props.txn)}
            </div>
        );
    }
}

export default withReferenceData(TransactionDetailComponent);

import intl from 'react-intl-universal';

export const SearchUserBy = Object.freeze({
    CORE_ID: 'Core ID',
    LICENSE_ID: 'License ID',
    PSEUDONYM_ID: 'Pseudonym ID',
    ASSET_ID: 'Asset ID',
    VME_USER_ID: 'Verified Me User ID',
});

export const getSearchUserByDisplayText = () => ({
    CORE_ID: intl.get('coreId'),
    LICENSE_ID: intl.get('licenseId'),
    PSEUDONYM_ID: intl.get('pseudonymId'),
    ASSET_ID: intl.get('assetId'),
});

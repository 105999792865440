import axios from 'axios';

export const setLockboxStatus = (caseId, lockboxId, status) => {
    return axios.post('/api/setLockboxStatus', {
        caseId,
        lockboxId,
        status,
    });
};

export const deleteLockbox = (caseId, lockboxId) => {
    return axios.post('/api/deleteLockbox', {
        caseId,
        lockboxId,
    });
};

import React from 'react';
import intl from 'react-intl-universal';
import './TransactionProofs.css';

import {connect} from 'react-redux';

import moment from 'moment';

import UserActivityTransactionTable from '../../components/UserActivityTransactionTable';

import {
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    Button,
    CircularProgress,
} from '@material-ui/core';

import DateRange from '../../components/DateRange';
import EditableChipList from '../../components/EditableChipList';
import {showNotification} from '../../actions/notificationActions';
import {withAuthorization} from '../../hocomponents/WithAuthorization';

@connect(store => ({
    notificationState: store.notification,
    selectedUser: store.user.selectedUser,
}))
class TransactionProofs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lookUpTxnsBy: 'date-range',
            dateRange: {
                from: null,
                to: null,
            },
            transactionIds: [],
        };
    }

    handleRadioGroupChange = event => this.setState({lookUpTxnsBy: event.target.value});

    handleViewTransactions = () => {
        const {selectedUser} = this.props;
        const {lookUpTxnsBy, dateRange, transactionIds} = this.state;

        if (!selectedUser || !selectedUser.verifiedMeUserId) {
            this.props.dispatch(showNotification(intl.get('noUserSelected'), 'error'));
            return;
        }

        if (lookUpTxnsBy === 'date-range') {
            // date from is missing
            if (!dateRange.from) {
                this.props.dispatch(showNotification(intl.get('dateFromMissing'), 'warning'));
                return;
            }
            // date to is missing
            if (!dateRange.to) {
                this.props.dispatch(showNotification(intl.get('dateToMissing'), 'warning'));
                return;
            }
            // date from can't be greater than date to
            if (moment(dateRange.from).isAfter(moment(dateRange.to))) {
                this.props.dispatch(showNotification(intl.get('dateFromGreaterThanTo'), 'warning'));
                return;
            }
        } else if (lookUpTxnsBy === 'txn-list') {
            if (transactionIds.length === 0) {
                this.props.dispatch(showNotification(intl.get('transactionListEmpty'), 'warning'));
                return;
            }
        }

        let searchCriteria =
            lookUpTxnsBy === 'date-range'
                ? {
                      dateRange: {
                          ...dateRange,
                          to: dateRange.to + 60 * 60 * 24,
                      },
                  }
                : {transactionIds: transactionIds};

        this.props.fetchTransactions(searchCriteria);
    };

    handleDateFromChange = d => {
        this.setState(prevState => ({
            dateRange: {
                ...prevState.dateRange,
                from: d,
            },
        }));
    };

    handleDateToChange = d => {
        this.setState(prevState => ({
            dateRange: {
                ...prevState.dateRange,
                to: d,
            },
        }));
    };

    handleTransactionIdsChanged = txnIds => this.setState({transactionIds: txnIds});

    renderRadioGroup() {
        return (
            <RadioGroup
                value={this.state.lookUpTxnsBy}
                onChange={this.handleRadioGroupChange}
                style={{flexDirection: 'row'}}
                aria-label={intl.get('ariaLabelUserActivityQueryRadioGroup')}>
                <FormControlLabel
                    value={'date-range'}
                    control={<Radio color="primary" />}
                    label={intl.get('dateRange')}
                />
                <FormControlLabel
                    value={'txn-list'}
                    control={<Radio color="primary" />}
                    label={intl.get('transactionList')}
                />
            </RadioGroup>
        );
    }

    renderUserActivityTransactionTable(txns) {
        return (
            <UserActivityTransactionTable
                transactions={txns}
                handleChangeTransactionsMarked={this.props.handleChangeTransactionsMarked}
            />
        );
    }

    renderByDateRangeTransactionLookUp() {
        const {dateRange} = this.state;

        let dateRangeDefaults = {
            defaultFromDate: dateRange.from ? moment.unix(dateRange.from).toDate() : null,
            defaultToDate: dateRange.to ? moment.unix(dateRange.to).toDate() : null,
        };
        return (
            <div>
                <p>{intl.get('dateRangePrompt')}:</p>
                <div className="row">
                    <DateRange
                        handleDateFromChange={this.handleDateFromChange}
                        handleDateToChange={this.handleDateToChange}
                        {...dateRangeDefaults}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{minWidth: 200}}
                        onClick={this.handleViewTransactions}
                        disabled={!dateRange.from || !dateRange.to}>
                        {intl.get('viewTransactions')}
                    </Button>
                </div>
            </div>
        );
    }

    renderByListTransactionLookUp() {
        const {transactionIds} = this.state;
        return (
            <div>
                <p>{intl.get('transactionCodePrompt')}:</p>
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        margin: '21px 0 1px 0',
                        justifyContent: 'flex-end',
                    }}>
                    <EditableChipList
                        handleChipsChanged={this.handleTransactionIdsChanged.bind(this)}
                    />
                    <Button
                        style={{minWidth: 200, alignSelf: 'flex-end'}}
                        variant="outlined"
                        color="primary"
                        onClick={this.handleViewTransactions}
                        disabled={!transactionIds || !transactionIds.length}>
                        {intl.get('viewTransactions')}
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const {lookUpTxnsBy} = this.state;
        const {fetchedTransactions, isLoading} = this.props;

        return (
            <div className="transactionProofs">
                <div className="title-radio-row">
                    <Typography variant="h5" gutterBottom={true} component="h3">
                        {intl.get('transactionProofs')}
                    </Typography>
                    {this.renderRadioGroup()}
                </div>
                <div className="container" aria-busy={isLoading}>
                    {lookUpTxnsBy === 'date-range' && this.renderByDateRangeTransactionLookUp()}
                    {lookUpTxnsBy === 'txn-list' && this.renderByListTransactionLookUp()}
                    {isLoading ? (
                        <CircularProgress
                            size={50}
                            aria-label={intl.get('ariaLabelCircularProgress')}
                            aria-valuemin={0}
                            aria-valuemax={1}
                        />
                    ) : (
                        fetchedTransactions.length > 0 &&
                        this.renderUserActivityTransactionTable(fetchedTransactions)
                    )}
                </div>
            </div>
        );
    }
}

export default withAuthorization(['proofs'])(TransactionProofs);

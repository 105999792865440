import React from 'react';
import intl from 'react-intl-universal';
import './DateRange.css';

import moment from 'moment';

import {MuiPickersUtilsProvider, DatePicker} from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';

class DateRange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: null,
            toDate: null,
        };
    }

    handleDateFromChange = date => {
        this.setState(prevState => ({...prevState, fromDate: date}));
        let unixDate = moment(new Date(date).setHours(0, 0, 0, 0)).unix();
        this.props.handleDateFromChange(unixDate);
    };

    handleDateToChange = date => {
        this.setState(prevState => ({...prevState, toDate: date}));
        let unixDate = moment(new Date(date).setHours(0, 0, 0, 0)).unix();
        this.props.handleDateToChange(unixDate);
    };

    componentDidMount() {
        const {defaultFromDate, defaultToDate} = this.props;
        if (defaultFromDate) this.handleDateFromChange(defaultFromDate);
        if (defaultToDate) this.handleDateToChange(defaultToDate);
    }

    render() {
        const locale = intl.get('locale');
        moment.locale(locale);
        const dateFormat = 'MM/DD/YYYY';
        return (
            <MuiPickersUtilsProvider utils={MomentUtils} locale={locale} moment={moment}>
                <div className="date-range row">
                    <section className="section-container">
                        <DatePicker
                            keyboard
                            disableOpenOnEnter
                            name="date"
                            placeholder={dateFormat}
                            mask={value =>
                                value
                                    ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                                    : []
                            }
                            animateYearScrolling={false}
                            allowKeyboardControl={true}
                            className="date-picker"
                            id="date_picker_from"
                            label={`${intl.get('ariaLabelDatePickerFrom')} (${dateFormat})`}
                            emptyLabel={intl.get('from')}
                            format={dateFormat}
                            onChange={this.handleDateFromChange}
                            okLabel={intl.get('ok')}
                            cancelLabel={intl.get('cancel')}
                            value={this.state.fromDate}
                            initialFocusedDate={this.state.fromDate}
                            style={{marginRight: '16px'}}
                            KeyboardButtonProps={{
                                'aria-label': intl.get('ariaLabelCalendarButton'),
                                title: intl.get('ariaLabelCalendarButton'),
                                'aria-hidden': true,
                            }}
                        />

                        <DatePicker
                            keyboard
                            disableOpenOnEnter
                            name="date"
                            placeholder={dateFormat}
                            mask={value =>
                                value
                                    ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
                                    : []
                            }
                            animateYearScrolling={false}
                            allowKeyboardControl={true}
                            className="date-picker"
                            id="date_picker_to"
                            label={`${intl.get('ariaLabelDatePickerTo')} (${dateFormat})`}
                            emptyLabel={intl.get('to')}
                            format={dateFormat}
                            onChange={this.handleDateToChange}
                            okLabel={intl.get('ok')}
                            cancelLabel={intl.get('cancel')}
                            value={this.state.toDate}
                            initialFocusedDate={this.state.toDate}
                            style={{marginRight: '16px'}}
                            KeyboardButtonProps={{
                                'aria-label': intl.get('ariaLabelCalendarButton'),
                                title: intl.get('ariaLabelCalendarButton'),
                                'aria-hidden': true,
                            }}
                        />
                    </section>
                </div>
            </MuiPickersUtilsProvider>
        );
    }
}

export default DateRange;

import React from 'react';

import './EditableChipList.css';
import intl from 'react-intl-universal';

import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

class EditableChipList extends React.Component {
    constructor(props) {
        super(props);
        this.normalInputHelperText = intl.get('transactionCode');
        this.errorInputHelperText = intl.get('transactionCodeReq');
        this.state = {
            textInput: '',
            data: [],
            invalidInput: false,
            inputHelper: this.normalInputHelperText,
        };
    }

    handleDeleteChip(key) {
        const {data} = this.state;
        let newData = data;
        const chipToDelete = newData.findIndex(d => d.key === key);
        newData.splice(chipToDelete, 1);
        newData = newData.map((e, i) => ({...e, key: i}));
        this.setState({data: newData});

        this.props.handleChipsChanged(newData.map(d => d.label));
    }

    renderChip(data) {
        return (
            <Chip
                component="button"
                label={data.label}
                color="primary"
                key={data.key}
                onDelete={() => this.handleDeleteChip(data.key)}
                onClick={() => this.handleDeleteChip(data.key)}
                style={{margin: '0 4px 4px 0'}}
                aria-label={`${intl.get('ariaLabelTransactionCodeChip')} ${data.label}`}
            />
        );
    }

    isValidTransactionCode(txnCode) {
        return txnCode.match(/^[A-Z]{6}$/);
    }

    handleTextFieldChange(event) {
        let userInput = event.target.value.toUpperCase();
        if (!userInput || this.isValidTransactionCode(userInput)) {
            this.setState({
                textInput: userInput,
                invalidInput: false,
                inputHelper: this.normalInputHelperText,
            });
        } else {
            this.setState({
                textInput: userInput,
                invalidInput: true,
                inputHelper: this.errorInputHelperText,
            });
        }
    }

    handleAddTxnCode() {
        const {data, textInput} = this.state;

        if (textInput.length === 0) {
            return;
        }

        const newData = [
            ...data,
            {
                key: data.length,
                label: textInput,
            },
        ];

        this.setState({
            data: newData,
            textInput: '',
        });

        this.props.handleChipsChanged(newData.map(d => d.label));
    }

    render() {
        const {data, textInput, invalidInput, inputHelper} = this.state;
        return (
            <div className="chipListContainer">
                <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: 5}}>
                    {data.map(this.renderChip, this)}
                </div>

                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <TextField
                        id="txn-code"
                        label={inputHelper}
                        value={textInput}
                        onChange={event => this.handleTextFieldChange(event)}
                        style={{marginRight: 16, flex: 3}}
                        inputProps={{className: 'txnListInput'}}
                        autoFocus
                        error={invalidInput}
                        onKeyPress={evt => {
                            if (invalidInput) {
                                return;
                            }
                            switch (evt.key) {
                                case 'Enter':
                                    this.handleAddTxnCode();
                                    break;
                                default:
                            }
                        }}
                    />
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.handleAddTxnCode()}
                        style={{alignSelf: 'flex-end', marginRight: 8}}
                        disabled={invalidInput}>
                        {intl.get('add')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default EditableChipList;

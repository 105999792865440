import React from 'react';
import intl from 'react-intl-universal';
import './InvalidRolesPage.css';
import vmeLogo from '../../../images/interac-logo.svg';

import {Paper} from '@material-ui/core';

export default class InvalidRolesPage extends React.Component {
    componentDidMount() {
        document.title = intl.get('invalidRolesPageName').d('DLBP Support Tool - Invalid Roles');
    }

    render() {
        return (
            <div className="dialogContainer">
                <Paper className="invalidRolesPage" square elevation={10}>
                    <div className="token" role="banner">
                        <div className="vmeLogoBg">
                            <img src={vmeLogo} alt={intl.get('vmeLogo')} width="50" height="50" />
                        </div>
                        <div className="pageName">
                            <h1 style={{fontSize: '1em'}}>{intl.get('invalidRolesPageName')}</h1>
                        </div>
                    </div>
                    <div className="pageContent" role="main">
                        <div className="error">{intl.get('invalidRolesPrompt')}</div>
                    </div>
                </Paper>
            </div>
        );
    }
}

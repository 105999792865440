import {getUserInfo} from '../api/auth';

export const fetchUserInfo = () => {
    return {
        type: 'FETCH_USERINFO',
        payload: getUserInfo(),
    };
};

export const logOut = () => ({
    type: 'LOG_OUT',
});

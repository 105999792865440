import React from 'react';
import SideBar from '../components/SideBar';
import TopBar from '../components/TopBar';
import intl from 'react-intl-universal';

export const withTopBarSideBar = WrappedComponent => {
    class WithTopBarSideBar extends React.Component {
        render() {
            const {auth} = this.props;
            const {roles, agentId} = auth;
            return (
                <React.Fragment>
                    <SideBar roles={roles} />
                    <TopBar pageName={intl.get('appTitle')} agentName={agentId} />
                    <WrappedComponent {...this.props} />
                </React.Fragment>
            );
        }
    }
    return WithTopBarSideBar;
};

export default withTopBarSideBar;

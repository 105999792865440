import React from 'react';
import intl from 'react-intl-universal';
import './UserActivityTransactionTable.css';

import {connect} from 'react-redux';
import moment from 'moment';

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    TablePagination,
    TableFooter,
} from '@material-ui/core';

import {RemoveCircleOutline, AddCircleOutline} from '@material-ui/icons';

import TransactionDetailsComponent from '../../components/TransactionDetailsComponent';
import CaseIdAnchor from '../../components/CaseIdAnchor';

import TransactionType from '../../enumerations/TransactionType';
import {getFormattedTime} from '../../utils';

@connect(store => ({
    ticketSelected: store.ticket.ticketSelected,
}))
class UserActivityTransactionTable extends React.Component {
    constructor(props) {
        super(props);
        this.txnFieldsToShowTitles = [
            {title: intl.get('txnCode'), ariaLabel: intl.get('ariaLabelColumnTxnCode')},
            {title: intl.get('dateTime'), ariaLabel: intl.get('ariaLabelColumnTxnDate')},
            {title: intl.get('device'), ariaLabel: intl.get('ariaLabelColumnTxnDevice')},
            {title: intl.get('activity'), ariaLabel: intl.get('ariaLabelColumnTxnActivity')},
            {title: intl.get('details'), ariaLabel: intl.get('ariaLabelColumnTxnDetails')},
            {title: intl.get('fraudCaseId'), ariaLabel: intl.get('ariaLabelColumnTxnCaseId')},
        ];
        this.txnFields = {
            userEventId: 'userEventId',
            date: 'date',
            device: 'device',
            type: 'type',
            transactionDetails: 'transactionDetails',
            fraudCaseId: 'fraudCaseId',
        };
        this.txnFieldsToShow = [
            this.txnFields.userEventId,
            this.txnFields.date,
            this.txnFields.device,
            this.txnFields.type,
            this.txnFields.transactionDetails,
            this.txnFields.fraudCaseId, //this.txnFields.userEventId, this.txnFields.date, this.txnFields.device, this.txnFields.type, this.txnFields.transactionDetails
        ];
        this.selectableEvents = [
            TransactionType.UserCreateLockbox,
            TransactionType.UserClaimPseudonym,
            TransactionType.UserCreateDA,
            TransactionType.UserIssueLicense,
            TransactionType.UserExecuteService,
            TransactionType.UserRecoverPseudonym,
        ];
        this.state = {
            page: 0,
            rowsPerPage: 10,
            selected: [],
        };
    }

    isRowSelectable = txn => {
        const {ticketSelected} = this.props;
        const isTransactionProofs =
            ticketSelected && ticketSelected.unlocalizedIssueType === 'Transaction Proofs';
        const canBeMarkedAsFraud = this.selectableEvents.includes(txn.type) && !txn.fraudCaseId;

        return isTransactionProofs || canBeMarkedAsFraud;
    };

    processValueForField = (txn, field) => {
        let value = txn[field];
        switch (field) {
            case this.txnFields.type:
                return intl.get(value) || value;
            case this.txnFields.date:
                moment.locale('fr');
                return getFormattedTime(value);
            case this.txnFields.device:
                return value && value.platform.length ? value.platform : '-';
            case this.txnFields.transactionDetails:
                return <TransactionDetailsComponent txn={txn} ariaLabel={field.ariaLabel} />;
            case this.txnFields.fraudCaseId:
                return value ? (
                    <CaseIdAnchor
                        href={txn.fraudCaseUrl}
                        text={value}
                        aria-label={field.ariaLabel}
                    />
                ) : (
                    '-'
                );
            default:
                return value;
        }
    };

    handleRowSelectClick = (event, txn) => {
        // if the row is not selectable don't do anything
        if (!this.isRowSelectable(txn)) {
            return;
        }

        const {selected} = this.state;

        const selectedIndex = selected.findIndex(elem => elem.id === txn.id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, txn);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        this.props.handleChangeTransactionsMarked(newSelected);
        this.setState({selected: newSelected});
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    isSelected = id => this.state.selected.findIndex(elem => elem.id === id) !== -1;

    render() {
        const {transactions} = this.props;
        const {page, rowsPerPage} = this.state;

        return (
            <div aria-live="polite">
                <Table
                    tabIndex={0}
                    aria-labelledby="title-user-activity"
                    className="userActivityTxnTable"
                    summary={intl.get('ariaLabelUserActivityTxnTable')}>
                    <TableHead>
                        <TableRow>
                            {this.txnFieldsToShowTitles.map((fieldItem, i) => (
                                <TableCell
                                    key={i}
                                    aria-label={fieldItem.ariaLabel}
                                    className="head-cell">
                                    {fieldItem.title}
                                </TableCell>
                            ))}
                            <TableCell
                                padding="checkbox"
                                className="head-cell"
                                aria-label={intl.get('ariaLabelColumnToggleCheckbox')}>
                                {intl.get('thTitleToggleTxn')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((txn, i) => {
                                const isSelected = this.isSelected(txn.id);
                                return (
                                    <TableRow
                                        hover
                                        key={i}
                                        style={isSelected ? {backgroundColor: '#FCE4EC'} : {}}
                                        aria-label={intl.get('ariaLabelUserActivityTxnRow')}>
                                        {this.txnFieldsToShow.map((field, i) => (
                                            <TableCell key={i} className="row-cell">
                                                {this.processValueForField(txn, field)}
                                            </TableCell>
                                        ))}
                                        <TableCell
                                            padding="checkbox"
                                            className="row-cell"
                                            key={this.txnFieldsToShow.length}
                                            aria-label={intl.get(
                                                'ariaLabelUserActivityTxnRowCheckbox'
                                            )}>
                                            {this.isRowSelectable(txn) ? (
                                                <Checkbox
                                                    icon={
                                                        typeof this.props
                                                            .handleTransactionRowClicked !==
                                                        'undefined' ? (
                                                            <RemoveCircleOutline />
                                                        ) : (
                                                            <AddCircleOutline />
                                                        )
                                                    }
                                                    checkedIcon={<RemoveCircleOutline />}
                                                    checked={isSelected}
                                                    onClick={event => {
                                                        if (
                                                            this.props.handleTransactionRowClicked
                                                        ) {
                                                            this.props.handleTransactionRowClicked(
                                                                event,
                                                                txn
                                                            );
                                                            return;
                                                        }
                                                        this.handleRowSelectClick(event, txn);
                                                    }}
                                                    inputProps={{
                                                        alt: intl.get(
                                                            'ariaLabelUserActivityTxnToggle',
                                                            {txnId: txn.userEventId}
                                                        ),
                                                        'aria-label': intl.get(
                                                            'ariaLabelUserActivityTxnToggle',
                                                            {txnId: txn.userEventId}
                                                        ),
                                                    }}
                                                />
                                            ) : null}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelDisplayedRows={({from, to, count}) =>
                                    intl.get('paginationTemplate', {
                                        from: from,
                                        to: to,
                                        count: count,
                                    })
                                }
                                count={transactions.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                rowsPerPageOptions={[5, 10, 15, 20]}
                                backIconButtonProps={{
                                    'aria-label': intl.get('previousPage'),
                                }}
                                nextIconButtonProps={{
                                    'aria-label': intl.get('nextPage'),
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                SelectProps={{
                                    SelectDisplayProps: {
                                        'aria-label': `${intl.get('rowsPerPage')} ${rowsPerPage}`,
                                        role: 'group',
                                    },
                                }}
                                aria-label={intl.get('ariaLabelTablePagination')}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        );
    }
}

export default UserActivityTransactionTable;

const defaultState = () => {
    return {
        ticketsAssigned: [],
        ticketsAssignedOrder: 'desc',
        ticketsAssignedOrderBy: 'caseId',
        ticketSelected: null,
        newTicketPending: null,
        newTicketReason: 'none',
        fetchingTicketsForAgent: false,
        fetchingNewTicketId: false,
    };
};

export default function reducer(state, action) {
    if (!state) {
        return defaultState();
    }
    switch (action.type) {
        /**
         *
         * /findCasesForAgent
         *
         */
        case 'FETCH_TICKETS_FULFILLED': {
            const caseId = action.meta;
            let newState = {
                ...state,
                ticketsAssigned: action.payload.cases,
                fetchingTicketsForAgent: false,
            };
            if (caseId) {
                let t = action.payload.cases.find(t => t.caseId === caseId);
                if (t) {
                    newState.ticketSelected = t;
                }
            }
            return newState;
        }
        case 'FETCH_TICKETS_PENDING': {
            return {
                ...state,
                // show loading only if we have no fetched tickets
                fetchingTicketsForAgent: state.ticketsAssigned.length === 0,
            };
        }
        case 'FETCH_TICKETS_REJECTED': {
            return {
                ...state,
                fetchingTicketsForAgent: false,
            };
        }

        /**
         *
         * /createCase
         *
         */
        case 'CREATE_NEW_TICKET_FULFILLED': {
            return {
                ...state,
                fetchingNewTicketId: false,
                newTicketPending: {
                    caseId: action.payload.caseId,
                    caseUrl: action.payload.caseUrl,
                    verifiedMeUserId: action.payload.verifiedMeUserId,
                    status: action.payload.status,
                    assignee: action.payload.assignee,
                    reasonCode: action.payload.reasonCode,
                },
                ticketSelected: null,
                newTicketReason: 'none',
            };
        }
        case 'CREATE_NEW_TICKET_PENDING': {
            return {
                ...state,
                fetchingNewTicketId: true,
            };
        }
        case 'CREATE_NEW_TICKET_REJECTED': {
            return {
                ...state,
                fetchingNewTicketId: false,
                newTicketPending: null,
                newTicketReason: 'none',
            };
        }

        case 'START_CASE_FULFILLED':
            // update the status of our local copies (case and cases assigned)
            let startedCase = {...action.meta, status: action.payload.status};
            return {
                ...state,
                ticketsAssigned: state.ticketsAssigned.map(t =>
                    t.caseId === startedCase.caseId ? startedCase : t
                ),
                newTicketPending: null,
                ticketSelected: {
                    ...state.ticketSelected,
                    ...action.payload,
                },
            };

        case 'SELECT_TICKET': {
            return {
                ...state,
                ticketSelected: action.payload,
            };
        }

        case 'SELECT_CASE_BY_ID': {
            const id = action.payload;
            return {
                ...state,
                ticketSelected: state.ticketsAssigned.find(ticket => ticket.caseId === id),
            };
        }

        case 'CLEAR_TICKET': {
            return {
                ...state,
                ticketSelected: null,
                newTicketPending: null,
            };
        }

        case 'CLEAR_NEW_TICKET_PENDING': {
            return {
                ...state,
                newTicketPending: null,
            };
        }

        case 'CHANGE_NEW_TICKET_REASON':
            return {
                ...state,
                newTicketReason: action.payload,
            };

        case 'ORDER_CASES_BY': {
            const property = action.payload;
            const orderBy = property;
            let order = 'desc';

            if (
                state.ticketsAssignedOrderBy === property &&
                state.ticketsAssignedOrder === 'desc'
            ) {
                order = 'asc';
            }

            const data =
                order === 'desc'
                    ? state.ticketsAssigned.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                    : state.ticketsAssigned.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

            return {
                ...state,
                ticketsAssigned: data,
                ticketsAssignedOrder: order,
                ticketsAssignedOrderBy: orderBy,
            };
        }
        default:
    }
    return state;
}

import {findTransactions} from '../api/common';
import {populateCaseUrl} from '../reducers/transactionReducer';
import {isLegalAgent} from '../reducers/authReducer';

export const fetchTransactions = (verifiedMeUserId, caseId, searchCriteria, roles) => ({
    type: 'FETCH_TRANSACTIONS',
    payload: findTransactions(verifiedMeUserId, caseId, searchCriteria).then(res => {
        if (!isLegalAgent(roles)) {
            return populateCaseUrl(res.transactions).then(txns => {
                res.transactions = txns;
                return res;
            });
        } else {
            return res;
        }
    }),
});

export const clearUserTransactions = () => ({
    type: 'CLEAR_USER_TRANSACTIONS',
});

export const setMarkedTransactions = markedTxns => ({
    type: 'SET_MARKED_TRANSACTIONS',
    payload: markedTxns,
});

export const clearMarkedTransactions = () => ({
    type: 'CLEAR_MARKED_TRANSACTIONS',
});

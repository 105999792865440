export default function reducer(state = {}, action) {
    switch (action.type) {
        case 'FETCH_REFERENCE_DATA_FULFILLED': {
            return action.payload;
        }
        default:
            break;
    }

    return state;
}

export const getDisplayNameFor = state => (type, key) => {
    const schema = state[type];
    if (!schema) return key;

    const dispName = schema[key];
    if (!dispName) return key;

    return dispName;
};

const defaultState = () => {
    return {
        selectedUser: {
            verifiedMeUserId: '',
            dlbpUserId: '',
            cases: [],
        },
        fetchingCasesForUser: false,
        searchError: false,
        isSearching: false,
    };
};

export default function reducer(state, action) {
    if (!state) {
        return defaultState();
    }
    switch (action.type) {
        case 'CLEAR_USER':
            return defaultState();

        /**
         * Fetch User
         */
        case 'FETCH_USER_FULFILLED': {
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    ...action.payload,
                },
                searchError: false,
                isSearching: false,
            };
        }
        case 'FETCH_USER_PENDING': {
            return {
                ...state,
                isSearching: true,
            };
        }
        case 'FETCH_USERINFO_TO_EXTEND_SESSION_REJECTED':
        case 'FETCH_USER_REJECTED': {
            return {
                ...state,
                selectedUser: null,
                searchError: true,
                isSearching: false,
            };
        }

        case 'SELECT_USER':
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    ...action.payload,
                },
            };
        case 'FETCH_CASES_FOR_USER_FULFILLED':
            return {
                ...state,
                fetchingCasesForUser: false,
                selectedUser: {
                    ...state.selectedUser,
                    cases: action.payload.cases,
                },
            };

        case 'FETCH_CASES_FOR_USER_PENDING':
            return {
                ...state,
                fetchingCasesForUser: true,
            };

        case 'FETCH_CASES_FOR_USER_REJECTED':
            return {
                ...state,
                fetchingCasesForUser: false,
            };

        case 'MARK_USER_SYNTHETIC':
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    isSynthetic: action.payload,
                },
            };
        case 'UPDATE_CASE_STATUS':
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    cases: updateCaseStatus(
                        state.selectedUser.cases,
                        action.payload.caseId,
                        action.payload.status
                    ),
                },
            };
        case 'ADD_NEW_CASE':
            return {
                ...state,
                selectedUser: {
                    ...state.selectedUser,
                    cases: addNewCase(state.selectedUser.cases, action.payload),
                },
            };
        case 'CLEAR_SEARCH_ERROR':
            return {
                ...state,
                searchError: false,
            };
        default:
    }
    return state;
}

const updateCaseStatus = (cases, caseId, status) =>
    cases.map(c => (c.caseId === caseId ? {...c, status: status} : c));

const addNewCase = (cases, newCase) => [newCase, ...cases];

import React from 'react';
import intl from 'react-intl-universal';
import './UserSearchBar.css';
import {connect} from 'react-redux';

import {
    Typography,
    TextField,
    Select,
    MenuItem,
    Button,
    InputLabel,
    FormControl,
    CircularProgress,
} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';

import {SearchUserBy, getSearchUserByDisplayText} from '../../enumerations/SearchUserBy';
import {fetchUser, clearSearchError, clearUser} from '../../actions/userActions';
import {clearUserTransactions, clearMarkedTransactions} from '../../actions/transactionActions';
import {clearLockboxes, clearMarkedLockboxes} from '../../actions/lockboxActions';

import {isCallCenterAgent} from '../../reducers/authReducer';
import {clearNewTicketPending} from '../../actions/ticketActions';

class UserSearchBar extends React.Component {
    constructor(props) {
        super(props);

        this.searchUserByDisplayText = getSearchUserByDisplayText();

        this.state = {
            searchBy: SearchUserBy.CORE_ID,
            searchByDisplayText: this.searchUserByDisplayText.CORE_ID,
            invalidInput: false,
        };

        this.queryValidator = {
            CORE_ID: /[\\s\\S]*/,
            LICENSE_ID: /^(?:[A-Za-z0-9-_]{43})$/, // base64URL encoded with NO padding, input size = 32 (sha256)
            PSEUDONYM_ID: /^(?:[A-Za-z0-9-_]{43})$/, // base64URL encoded with NO padding, input size = 32 (sha256)
            ASSET_ID: /^(?:[A-Za-z0-9-_]{4})*(?:[A-Za-z0-9-_]{2,4})$/, // base64URL encoded with NO padding, input size unknown (configured per DAP)
        };
    }

    clearState = () => {
        this.props.dispatch(clearUser());
        this.props.dispatch(clearNewTicketPending());
        this.props.dispatch(clearLockboxes());
        this.props.dispatch(clearMarkedLockboxes());
        this.props.dispatch(clearMarkedTransactions());
        this.props.dispatch(clearUserTransactions());
    };

    handleChangeSearchBy = event => {
        const {searchError} = this.props;
        if (searchError) {
            this.props.dispatch(clearSearchError());
        }

        let newDisplayText = '';
        switch (event.target.value) {
            case SearchUserBy.CORE_ID:
                newDisplayText = this.searchUserByDisplayText.CORE_ID;
                break;
            case SearchUserBy.PSEUDONYM_ID:
                newDisplayText = this.searchUserByDisplayText.PSEUDONYM_ID;
                break;
            case SearchUserBy.LICENSE_ID:
                newDisplayText = this.searchUserByDisplayText.LICENSE_ID;
                break;
            case SearchUserBy.ASSET_ID:
                newDisplayText = this.searchUserByDisplayText.ASSET_ID;
                break;
            default:
                return;
        }

        this.setState({
            invalidInput: false,
            searchBy: event.target.value,
            searchByDisplayText: newDisplayText,
        });
    };

    handleChangeQuery = event => {
        const {searchError} = this.props;
        if (event.target.value === '' && searchError) {
            this.props.dispatch(clearSearchError());
        }

        this.setState({
            invalidInput: false,
            query: event.target.value,
        });
    };

    handleOnKeyDown = event => {
        const {searchBy, query} = this.state;
        if (event.key === 'Enter') {
            this.invokeSearch(searchBy, query);
        }
    };

    invokeSearch = (searchBy, query) => {
        const {auth, isSearching} = this.props;

        if (isSearching) {
            return;
        }

        this.clearState();

        if (!query) {
            this.setState({invalidInput: true});
            return;
        }

        switch (searchBy) {
            case SearchUserBy.CORE_ID:
                if (!this.queryValidator.CORE_ID.test(query)) {
                    this.setState({invalidInput: true});
                    return;
                }
                break;
            case SearchUserBy.PSEUDONYM_ID:
                if (!this.queryValidator.PSEUDONYM_ID.test(query)) {
                    this.setState({invalidInput: true});
                    return;
                }
                break;
            case SearchUserBy.LICENSE_ID:
                if (!this.queryValidator.LICENSE_ID.test(query)) {
                    this.setState({invalidInput: true});
                    return;
                }
                break;
            case SearchUserBy.ASSET_ID:
                if (!this.queryValidator.ASSET_ID.test(query)) {
                    this.setState({invalidInput: true});
                    return;
                }
                break;
            default:
        }

        this.props.dispatch(fetchUser(searchBy, query, !isCallCenterAgent(auth.roles)));
    };

    searchLabel = () => {
        const {searchError} = this.props;
        const {searchByDisplayText, invalidInput} = this.state;
        if (searchError) {
            return intl.get('searchFailed', {searchBy: searchByDisplayText});
        }
        if (invalidInput) {
            return intl.get('searchInvalidFormat', {searchBy: searchByDisplayText});
        }

        return intl.get('searchEnter', {searchBy: searchByDisplayText});
    };

    render() {
        const {roles, searchError, isSearching} = this.props;
        const {searchBy, query, invalidInput} = this.state;
        return (
            <div className="userSearchBar">
                <Typography variant="h5" gutterBottom={true} component="h1" color="primary">
                    {intl.get('findUser')}
                </Typography>

                <div className="container">
                    <TextField
                        id="user_search_input"
                        className="textfield"
                        type="search"
                        autoFocus={true}
                        error={!isSearching && (searchError || invalidInput)}
                        label={this.searchLabel()}
                        onChange={this.handleChangeQuery}
                        onKeyDown={this.handleOnKeyDown}
                    />

                    {// Call Center Agents can only search by Core ID
                    isCallCenterAgent(roles) ? null : (
                        <FormControl className="dropdown">
                            <InputLabel htmlFor="dropdown-search-by" id="label-search-by">
                                {intl.get('searchBy')}
                            </InputLabel>
                            <Select
                                value={searchBy}
                                onChange={this.handleChangeSearchBy}
                                SelectDisplayProps={{
                                    id: 'dropdown-search-by',
                                    'aria-labelledby': 'label-search-by',
                                    role: 'group',
                                }}>
                                <MenuItem value={SearchUserBy.CORE_ID}>
                                    {this.searchUserByDisplayText.CORE_ID}
                                </MenuItem>
                                <MenuItem value={SearchUserBy.LICENSE_ID}>
                                    {this.searchUserByDisplayText.LICENSE_ID}
                                </MenuItem>
                                <MenuItem value={SearchUserBy.PSEUDONYM_ID}>
                                    {this.searchUserByDisplayText.PSEUDONYM_ID}
                                </MenuItem>
                                <MenuItem value={SearchUserBy.ASSET_ID}>
                                    {this.searchUserByDisplayText.ASSET_ID}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    <Button
                        variant="contained"
                        className="searchBtn"
                        color="primary"
                        onClick={() => this.invokeSearch(searchBy, query)}
                        disabled={!query || isSearching}
                        aria-label="Search"
                        aria-busy={isSearching}>
                        {isSearching ? (
                            <CircularProgress
                                size={24}
                                aria-busy={true}
                                aria-label={intl.get('ariaLabelCircularProgress')}
                                aria-valuemin={0}
                                aria-valuemax={1}
                            />
                        ) : (
                            <SearchIcon aria-label="Search" aria-live="polite" />
                        )}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    isSearching: store.user.isSearching,
    searchError: store.user.searchError,
    roles: store.auth.roles,
});

export default connect(mapStateToProps)(UserSearchBar);

import * as fraudApi from '../api/fraud';
import intl from 'react-intl-universal';
import * as proofsApi from '../api/proofs';
import {showNotification} from './notificationActions';
import {clearTicket} from './ticketActions';
import {clearMarkedTransactions, clearUserTransactions} from './transactionActions';
import {updateCaseStatus} from './userActions';
import {FraudCaseStatus} from '../enumerations/FraudCaseStatus';

export const submitFraudReport = (caseId, reasonCode, transactionIds, cb) => dispatch => {
    dispatch({
        type: 'SUBMIT_FRAUD_REPORT',
        payload: fraudApi.createFraudReport(caseId, reasonCode, transactionIds).then(res => {
            dispatch(showNotification(intl.get('fraudReportSubmitted'), 'success'));
            dispatch(clearTicket());
            dispatch(clearMarkedTransactions());
            dispatch(clearUserTransactions());
            dispatch(hideReportDetails());
            dispatch(updateCaseStatus(caseId, FraudCaseStatus.reportSubmitted));
            if (typeof cb === 'function') {
                cb();
            }
        }),
    });
};

export const submitTransactionProofsReport = (caseId, transactionIds, cb) => dispatch => {
    dispatch({
        type: 'SUBMIT_TRANSACTION_PROOFS_REPORT',
        payload: proofsApi.releaseTransactionProofs(caseId, transactionIds).then(res => {
            dispatch(showNotification(intl.get('proofsReportSubmitted'), 'success'));
            dispatch(clearTicket());
            dispatch(clearMarkedTransactions());
            dispatch(clearUserTransactions());
            dispatch(hideReportDetails());
            dispatch(updateCaseStatus(caseId, FraudCaseStatus.proofSubmitted));
            if (typeof cb === 'function') {
                cb();
            }
        }),
    });
};

export const hideReportDetails = () => ({
    type: 'HIDE_REPORT_DETAILS',
});

export const showReportDetails = () => ({
    type: 'SHOW_REPORT_DETAILS',
});

import React from 'react';
import './UserLockboxes.css';
import {connect} from 'react-redux';
import intl from 'react-intl-universal';

import {getLockboxes} from '../../actions/lockboxActions';

import * as api from '../../api/common';

import {Typography, CircularProgress} from '@material-ui/core';

import {withAuthorization} from '../../hocomponents/WithAuthorization';
import LockboxTable from '../../components/LockboxTable';

@connect(store => ({
    lockboxes: store.lockbox.lockboxes,
    fetchingLockboxes: store.lockbox.fetchingLockboxes,
    ticketSelected: store.ticket.ticketSelected,
    newTicketPending: store.ticket.newTicketPending,
    selectedUser: store.user.selectedUser,
    roles: store.auth.roles,
}))
class UserLockboxes extends React.Component {
    componentDidMount() {
        const {ticketSelected, selectedUser, roles} = this.props;
        let verifiedMeUserId = ticketSelected
            ? ticketSelected.verifiedMeUserId
            : selectedUser.verifiedMeUserId;
        this.props.dispatch(getLockboxes(verifiedMeUserId, roles));
    }

    componentWillUnmount() {
        api.cancelRequests([api.getLockboxes.name]);
    }

    render() {
        const {lockboxes, fetchingLockboxes} = this.props;
        return (
            <div className="userLockboxes" aria-busy={fetchingLockboxes && !lockboxes.length}>
                <Typography
                    variant="h5"
                    gutterBottom={true}
                    component="h3"
                    id="title-user-lockboxes">
                    {intl.get('userLockboxes')}
                </Typography>
                {fetchingLockboxes && !lockboxes.length ? (
                    <CircularProgress
                        size={50}
                        aria-label={intl.get('ariaLabelCircularProgress')}
                        aria-valuemin={0}
                        aria-valuemax={1}
                    />
                ) : (
                    <LockboxTable />
                )}
            </div>
        );
    }
}

export default withAuthorization(['fraud', 'lockbox'])(UserLockboxes);

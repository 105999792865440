import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';

import CurrentTicket from '../../components/CurrentTicket';
import CurrentUser from '../../components/CurrentUser';
import ButtonBar from '../../components/ButtonBar';
import UserLockboxes from '../../components/UserLockboxes';
import UserActivity from '../../components/UserActivity';
import AgentAssignedCases from '../../components/AgentAssignedCases';
import ReportDetails from '../../components/ReportDetails';
import TransactionProofs from '../../components/TransactionProofs';
import FooterNote from '../../components/FooterNote';
import UserSearchBar from '../../components/UserSearchBar';
import WithTopBarSideBar from '../../hocomponents/WithTopBarSideBar';

// Actions
import {fetchUser, clearUser} from '../../actions/userActions';
import {
    fetchTransactions,
    clearUserTransactions,
    setMarkedTransactions,
    clearMarkedTransactions,
} from '../../actions/transactionActions';
import {
    showReportDetails,
    hideReportDetails,
    submitFraudReport,
    submitTransactionProofsReport,
} from '../../actions/reportActions';
import {fetchTickets, retrieveCase, clearTicket, orderCasesBy} from '../../actions/ticketActions';
import {setMarkedLockboxes, clearLockboxes} from '../../actions/lockboxActions';
import {showNotification} from '../../actions/notificationActions';

// Enums
import {FraudCaseStatus} from '../../enumerations/FraudCaseStatus';

// Constants
import {getReasonCodes} from '../../constants/ReasonCodes';

import * as api from '../../api/common';

class ActiveTicketsPage extends React.Component {
    constructor(props: ContentAreaProps) {
        super(props);
        this.state = {
            assignedTicketsOrder: 'caseId',
            assignedTicketsOrderBy: 'asc',
            reasonCode: getReasonCodes()[0].key,
        };
    }

    onSearch = (searchBy, query) => this.props.dispatch(fetchUser(searchBy, query));

    fetchUserTransactions(searchCriteria) {
        const {ticketState, userState, auth} = this.props;
        const {verifiedMeUserId} = userState.selectedUser;
        const {caseId} = ticketState.ticketSelected;
        const roles = auth.roles;
        this.props.dispatch(fetchTransactions(verifiedMeUserId, caseId, searchCriteria, roles));
    }

    handleReportReasonChange(event) {
        this.setState({
            reasonCode: event.target.value,
        });
    }

    handleChangeTransactionsMarked(txns) {
        this.props.dispatch(setMarkedTransactions(txns));
    }

    handleChangeLockboxesMarked(lbs) {
        this.props.dispatch(setMarkedLockboxes(lbs));
    }

    handleCreateReport() {
        this.props.dispatch(showReportDetails());
    }

    backFromReportDetails() {
        this.props.dispatch(clearMarkedTransactions());
        this.props.dispatch(hideReportDetails());
    }

    handleSubmitReport() {
        const {ticketState, transactionState, lockboxState} = this.props;

        const {ticketSelected} = ticketState;
        const {markedTransactions} = transactionState;
        const {markedLockboxes} = lockboxState;
        const {reasonCode} = this.state;

        if (reasonCode === 'none') {
            this.props.dispatch(showNotification(intl.get('mustSelectResolution'), 'warning'));
            return;
        }

        const lockboxCreateTransactionIds = (markedLockboxes || []).map(l => l.createTransactionId);
        const markedTransactionIds = (markedTransactions || []).map(t => t.id);

        this.props.dispatch(
            submitFraudReport(
                ticketSelected.caseId,
                reasonCode,
                [...markedTransactionIds, ...lockboxCreateTransactionIds],
                () => this.props.history.push('/active-cases')
            )
        );

        // reset reason code back to default
        this.setState({reasonCode: 'none'});
    }

    handleSubmitTransactionProofsReport() {
        const {ticketState, transactionState} = this.props;
        const {ticketSelected} = ticketState;
        const {markedTransactions} = transactionState;

        const markedTransactionIds = (markedTransactions || []).map(t => t.id);

        this.props.dispatch(
            submitTransactionProofsReport(ticketSelected.caseId, [...markedTransactionIds], () =>
                this.props.history.push('/active-cases')
            )
        );
    }

    handleTicketSelection(ticket) {
        this.props.history.push(`/active-cases/${ticket.caseId}`);
    }

    handleTicketSelectionCancel() {
        this.props.dispatch(clearTicket());
        this.props.dispatch(clearMarkedTransactions());
        this.props.dispatch(clearUserTransactions());
        this.props.dispatch(hideReportDetails());
        this.props.history.push('/active-cases');
    }

    handleRequestSort = (event, property) => {
        this.props.dispatch(orderCasesBy(property));
    };

    composeButtonBarNote(userTransactions, markedTransactions) {
        if (!userTransactions || userTransactions.length === 0) {
            return ``;
        } else if (markedTransactions.length > 0) {
            return intl.get('transactionsAdded', {numTransactions: markedTransactions.length});
        }
        return intl.get('createReportInst');
    }

    renderBasedOnTicketStatus(ticket, isTransactionProofs) {
        const {reasonCode} = this.state;
        const {transactionState, lockboxState, reportState, userState} = this.props;
        const {showReportDetails, isSubmitting} = reportState;

        const {userTransactions, markedTransactions, fetchingTransactions} = transactionState;
        const {markedLockboxes} = lockboxState;
        const {selectedUser} = userState;

        const {status} = ticket;
        const statusText = FraudCaseStatus[status];

        switch (statusText) {
            case FraudCaseStatus.closed:
                return <FooterNote text={intl.get('caseClosed')} />;
            case FraudCaseStatus.reportSubmitted:
            case FraudCaseStatus.proofSubmitted:
                return <FooterNote text={intl.get('reportAlreadySubmitted')} />;
            default:
                return (
                    <div>
                        {showReportDetails ? (
                            <ReportDetails
                                ticket={ticket}
                                lockboxes={markedLockboxes}
                                handleMarkedLockboxesChange={this.handleChangeLockboxesMarked.bind(
                                    this
                                )}
                                transactions={markedTransactions}
                                reasonCodes={getReasonCodes()}
                                reasonCode={reasonCode}
                                handleMarkedTransactionsChange={this.handleChangeTransactionsMarked.bind(
                                    this
                                )}
                                handleReasonCodeChange={this.handleReportReasonChange.bind(this)}
                                isSubmitting={isSubmitting}
                            />
                        ) : (
                            <div>
                                <UserLockboxes />
                                {selectedUser &&
                                    selectedUser.dlbpUserId &&
                                    (isTransactionProofs ? (
                                        <TransactionProofs
                                            fetchedTransactions={userTransactions}
                                            fetchTransactions={this.fetchUserTransactions.bind(
                                                this
                                            )}
                                            handleChangeTransactionsMarked={this.handleChangeTransactionsMarked.bind(
                                                this
                                            )}
                                            isLoading={fetchingTransactions}
                                        />
                                    ) : (
                                        <UserActivity
                                            ticket={ticket}
                                            fetchedTransactions={userTransactions}
                                            fetchTransactions={this.fetchUserTransactions.bind(
                                                this
                                            )}
                                            handleChangeTransactionsMarked={this.handleChangeTransactionsMarked.bind(
                                                this
                                            )}
                                            isLoading={fetchingTransactions}
                                        />
                                    ))}
                            </div>
                        )}

                        {selectedUser && selectedUser.dlbpUserId && (
                            <ButtonBar
                                cancelFn={
                                    showReportDetails
                                        ? this.backFromReportDetails.bind(this)
                                        : this.handleTicketSelectionCancel.bind(this)
                                }
                                cancelTxt={
                                    showReportDetails || isTransactionProofs
                                        ? intl.get('back')
                                        : intl.get('cancel')
                                }
                                submitFn={
                                    showReportDetails
                                        ? isTransactionProofs
                                            ? this.handleSubmitTransactionProofsReport.bind(this)
                                            : this.handleSubmitReport.bind(this)
                                        : this.handleCreateReport.bind(this)
                                }
                                submitTxt={
                                    showReportDetails
                                        ? intl.get('submitReport')
                                        : intl.get('createReport')
                                }
                                submitDisabled={
                                    showReportDetails &&
                                    (isTransactionProofs
                                        ? markedTransactions.length === 0
                                        : reasonCode === 'none')
                                }
                                note={this.composeButtonBarNote(
                                    userTransactions,
                                    markedTransactions
                                )}
                            />
                        )}
                    </div>
                );
        }
    }

    renderTicketCase(ticket) {
        const {userState, auth} = this.props;
        const {selectedUser, searchError} = userState;

        const isTransactionProofs = ticket && ticket.unlocalizedIssueType === 'Transaction Proofs';

        return (
            <React.Fragment>
                {isTransactionProofs && <UserSearchBar searchError={searchError} auth={auth} />}
                <div className="contentRow">
                    <CurrentTicket ticket={ticket} />
                    <CurrentUser user={selectedUser} />
                </div>
                {this.renderBasedOnTicketStatus(ticket, isTransactionProofs)}
            </React.Fragment>
        );
    }

    renderAgentAssignedCases(tickets, order, orderBy) {
        const {ticketState} = this.props;
        const {fetchingTicketsForAgent} = ticketState;

        return (
            <AgentAssignedCases
                tickets={tickets}
                onTicketSelection={this.handleTicketSelection.bind(this)}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort.bind(this)}
                isLoading={fetchingTicketsForAgent}
                routerHistory={this.props.history}
            />
        );
    }

    cancelOngoingRequests = () =>
        api.cancelRequests(
            [
                api.findCasesForAgent,
                api.findVerifiedMeUser,
                api.findTransactions,
                api.startCase,
            ].map(fn => fn.name)
        );

    render() {
        const {ticketState, match} = this.props;
        const {
            ticketSelected,
            ticketsAssigned,
            ticketsAssignedOrder,
            ticketsAssignedOrderBy,
        } = ticketState;
        const {caseId} = match.params;
        const caseIdExists = typeof caseId !== 'undefined' && caseId !== null && caseId.length;
        return (
            <div className="assignedTicketsPage">
                <div id="main-content" className="contentArea" role="main">
                    {!ticketSelected &&
                        !caseIdExists &&
                        this.renderAgentAssignedCases(
                            ticketsAssigned || [],
                            ticketsAssignedOrder,
                            ticketsAssignedOrderBy
                        )}
                    {ticketSelected && this.renderTicketCase(ticketSelected)}
                </div>
            </div>
        );
    }

    componentDidMount() {
        document.title = intl.get('activeCasesPageName').d('DLBP Support Tool - Active Cases');

        const {match} = this.props;
        // fetch tickets at this phase of the component lifecycle

        // match.params.caseId is the case id found in the url
        // e.g. /active-cases/VME-123
        // we pass it in retrieveCase()
        if (match.params.caseId) {
            this.props.dispatch(retrieveCase(match.params.caseId));
            document.title = `${document.title} - ${match.params.caseId}`;
            return;
        }

        // if case id not found in the url
        // we call fetchTickets() and display the tickets
        this.props.dispatch(fetchTickets());
    }

    componentWillUnmount() {
        this.cancelOngoingRequests();

        this.props.dispatch(clearUser());
        this.props.dispatch(clearLockboxes());
        this.props.dispatch(clearUserTransactions());
        this.props.dispatch(clearMarkedTransactions());
        this.props.dispatch(clearTicket());
        this.props.dispatch(hideReportDetails());
    }
}

const mapStateToProps = state => ({
    userState: state.user,
    ticketState: state.ticket,
    transactionState: state.transaction,
    lockboxState: state.lockbox,
    reportState: state.report,
    notificationState: state.notification,
    auth: state.auth,
});

export default connect(mapStateToProps)(WithTopBarSideBar(ActiveTicketsPage));

import React from 'react';
import intl from 'react-intl-universal';
import './AgentAssignedCases.css';
import FraudCaseTypes from '../../enumerations/FraudCaseTypes';

import moment from 'moment';

import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TablePagination,
    CircularProgress,
    TableFooter,
} from '@material-ui/core';

import {getFraudCaseStatusDisplayText} from '../../enumerations/FraudCaseStatus';
import {getFraudCaseReasonDisplayText} from '../../enumerations/FraudCaseReason';
import {getFormattedTime} from '../../utils';

class AgentAssignedCases extends React.Component {
    timerID: number;

    constructor(props) {
        super(props);
        this.state = {
            dateNow: moment(),
            page: 0,
            rowsPerPage: 10,
        };
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            60 * 1000 // update every minute
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            dateNow: moment(),
        });
    }

    getFraudCaseType(type) {
        switch (type) {
            case FraudCaseTypes.DLBP_FRAUD_EVENT:
                return intl.get('dlbpFraudEvent').d(type);
            case FraudCaseTypes.DAC_FRAUD_EVENT:
                return intl.get('dacFraudEvent').d(type);
            case FraudCaseTypes.DAP_FRAUD_EVENT:
                return intl.get('dapFraudEvent').d(type);
            case FraudCaseTypes.TRANSACTION_PROOFS:
                return intl.get('transactionProofs').d(type);
            case FraudCaseTypes.TRANSACTION_QUERY:
                return intl.get('transactionQuery').d(type);
            case FraudCaseTypes.LOCKBOX:
                return intl.get('lockbox').d(type);
            case FraudCaseTypes.LOCKBOX_MANAGEMENT:
                return intl.get('lockboxManagement').d(type);
            default:
                return type;
        }
    }

    calculateResolutionTime = d => {
        const {dateNow} = this.state;
        return d.diff(dateNow, 'minutes');
    };

    styleResolutionTimeLabel = dateEpoch => {
        let style = {fontWeight: 'bold', color: '#d41111'};

        let m = this.calculateResolutionTime(moment.unix(dateEpoch)); // minutes
        if (m > 0) {
            style = {...style, color: '#007700'};
        }

        let resolutionString = getFormattedTime(dateEpoch);

        return <span style={style}>{resolutionString}</span>;
    };

    handleChangePage = (event, page) => {
        this.setState({page});
    };

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    handleTicketSelection = ticket => {
        this.props.onTicketSelection(ticket);
    };

    renderAgentAssignedCases(tickets, order, orderBy) {
        const {page, rowsPerPage} = this.state;
        const fraudCaseStatusText = getFraudCaseStatusDisplayText();
        const fraudCaseReason = getFraudCaseReasonDisplayText();
        const makeSortableColumnHeadCell = (key, title, ariaLabel) => {
            const isActive = orderBy === key;
            const ariaSort = !isActive ? 'none' : order === 'asc' ? 'ascending' : 'descending';
            return (
                <TableCell
                    className="head-cell"
                    sortDirection={isActive ? order : false}
                    aria-label={ariaLabel}>
                    <TableSortLabel
                        active={isActive}
                        direction={order}
                        onClick={this.createSortHandler(key)}
                        className="tableSortLabel"
                        aria-sort={ariaSort}
                        aria-label={intl.get('sortByColumn', {title})}>
                        {title}
                    </TableSortLabel>
                </TableCell>
            );
        };
        return (
            <div aria-live="polite">
                <Table
                    tabIndex={0}
                    aria-labelledby="title-cases-assigned"
                    summary={intl.get('tableSummaryCasesAssignedToYou')}>
                    <TableHead>
                        <TableRow>
                            {makeSortableColumnHeadCell(
                                'caseId',
                                intl.get('caseId'),
                                intl.get('ariaLabelColumnCaseId')
                            )}
                            {makeSortableColumnHeadCell(
                                'type',
                                intl.get('type'),
                                intl.get('ariaLabelColumnType')
                            )}
                            {makeSortableColumnHeadCell(
                                'reasonCode',
                                intl.get('reasonCode'),
                                intl.get('ariaLabelColumnReasonCode')
                            )}
                            {makeSortableColumnHeadCell(
                                'createdTime',
                                intl.get('createdTime'),
                                intl.get('ariaLabelColumnCreatedTime')
                            )}
                            {makeSortableColumnHeadCell(
                                'requiredResolutionTime',
                                intl.get('requiredResolutionTime'),
                                intl.get('ariaLabelColumnRequiredResolutionTime')
                            )}
                            {makeSortableColumnHeadCell(
                                'status',
                                intl.get('status'),
                                intl.get('ariaLabelColumnStatus')
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tickets
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((t, i) => (
                                <TableRow
                                    hover
                                    aria-label={intl.get('ariaLabelActiveCasesRowClick', {
                                        caseId: t.caseId,
                                    })}
                                    key={i}
                                    tabIndex={0}
                                    style={{cursor: 'pointer'}}
                                    onClick={() => this.handleTicketSelection(t)}
                                    onKeyPress={evt => {
                                        switch (evt.key) {
                                            case 'Enter':
                                            case ' ':
                                                this.handleTicketSelection(t);
                                                break;
                                            default:
                                        }
                                    }}>
                                    <TableCell className="row-cell">{t.caseId}</TableCell>
                                    <TableCell className="row-cell">
                                        {this.getFraudCaseType(t.type)}
                                    </TableCell>
                                    <TableCell className="row-cell">
                                        {fraudCaseReason[t.reasonCode] || t.reasonCode || '-'}
                                    </TableCell>
                                    <TableCell className="row-cell">
                                        {getFormattedTime(t.createdTime)}
                                    </TableCell>
                                    <TableCell className="row-cell">
                                        {t.requiredResolutionTime
                                            ? this.styleResolutionTimeLabel(
                                                  t.requiredResolutionTime
                                              )
                                            : '-'}
                                    </TableCell>
                                    <TableCell className="row-cell">
                                        {fraudCaseStatusText[t.status] || t.status}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                labelDisplayedRows={({from, to, count}) =>
                                    intl.get('paginationTemplate', {
                                        from: from,
                                        to: to,
                                        count: count,
                                    })
                                }
                                count={tickets.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                rowsPerPageOptions={[5, 10, 15, 25, 50]}
                                backIconButtonProps={{
                                    'aria-label': intl.get('previousPage'),
                                }}
                                nextIconButtonProps={{
                                    'aria-label': intl.get('nextPage'),
                                }}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                SelectProps={{
                                    SelectDisplayProps: {
                                        'aria-label': `${intl.get('rowsPerPage')} ${rowsPerPage}`,
                                        role: 'group',
                                    },
                                }}
                                aria-label={intl.get('ariaLabelTablePagination')}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        );
    }

    render() {
        const {tickets, order, orderBy, isLoading} = this.props;
        return (
            <div className="assignedTickets" aria-busy={isLoading}>
                <Typography
                    variant="h5"
                    gutterBottom={true}
                    component="h1"
                    color="primary"
                    id="title-cases-assigned">
                    {intl.get('casesAssigned')}
                </Typography>
                {isLoading && !tickets.length ? (
                    <CircularProgress
                        size={50}
                        aria-label={intl.get('ariaLabelCircularProgress')}
                    />
                ) : (
                    this.renderAgentAssignedCases(tickets, order, orderBy)
                )}
            </div>
        );
    }
}

export default AgentAssignedCases;

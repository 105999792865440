//@flow
import React from 'react';
import intl from 'react-intl-universal';
import './ReportDetails.css';

import {
    Typography,
    FormControl,
    Select,
    MenuItem,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    InputLabel,
} from '@material-ui/core';

import {RemoveCircleOutline} from '@material-ui/icons';

import UserActivityTransactionTable from '../../components/UserActivityTransactionTable';
import {withAuthorization} from '../../hocomponents/WithAuthorization';
import {getFormattedTime} from '../../utils'

class ReportDetails extends React.Component {
    handleTransactionRowClicked = (event, txn) => {
        const {transactions} = this.props;
        this.props.handleMarkedTransactionsChange(transactions.filter(t => t.id !== txn.id));
    };

    handleLockboxRowRemove = lb => {
        const {lockboxes} = this.props;
        this.props.handleMarkedLockboxesChange(lockboxes.filter(l => l.id !== lb.id));
    };

    handleReActivateLockboxChange = event =>
        this.setState({reActivateLockbox: event.target.checked});

    renderTransactionsToReport = txns => {
        return (
            <UserActivityTransactionTable
                transactions={txns}
                handleTransactionRowClicked={this.handleTransactionRowClicked.bind(this)}
            />
        );
    };

    renderLockboxesToReport = lockboxes => {
        return (
            <Table
                tabIndex={0}
                aria-labelledby="title-report-details"
                summary={intl.get('tableSummaryReportDetails')}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            aria-label={intl.get('ariaLabelColumnLockboxId')}
                            className="head-cell">
                            {intl.get('lockboxId')}
                        </TableCell>
                        <TableCell
                            aria-label={intl.get('ariaLabelColumnLockboxStatus')}
                            className="head-cell">
                            {intl.get('status')}
                        </TableCell>
                        <TableCell
                            aria-label={intl.get('ariaLabelColumnLockboxCreatedTime')}
                            className="head-cell">
                            {intl.get('created')}
                        </TableCell>
                        <TableCell
                            aria-label={intl.get('ariaLabelColumnLockboxDeletedTime')}
                            className="head-cell">
                            {intl.get('deleted')}
                        </TableCell>
                        <TableCell
                            aria-label={intl.get('ariaLabelColumnLockboxDevices')}
                            className="head-cell">
                            {intl.get('devices')}
                        </TableCell>
                        <TableCell
                            aria-label={intl.get('ariaLabelColumnLockboxRemoveRow')}
                            className="head-cell">
                            {intl.get('thTitleToggleTxn')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(lockboxes || []).map((lb, i) => {
                        return (
                            <TableRow key={i} tabIndex={-1}>
                                <TableCell className="row-cell">{lb.id}</TableCell>
                                <TableCell className="row-cell">{lb.status}</TableCell>
                                <TableCell className="row-cell">
                                    {getFormattedTime(lb.createDate)}
                                </TableCell>
                                <TableCell className="row-cell">
                                    {lb.deleteDate
                                        ? getFormattedTime(lb.deleteDate)
                                        : '-'}
                                </TableCell>
                                <TableCell className="row-cell">
                                    {lb.devices.map(x => x.platform).join(', ')}
                                </TableCell>
                                <TableCell padding="checkbox" className="row-cell">
                                    <Checkbox
                                        onClick={() => this.handleLockboxRowRemove(lb)}
                                        icon={<RemoveCircleOutline />}
                                        checkedIcon={<RemoveCircleOutline />}
                                        inputProps={{
                                            alt: intl.get('ariaLabelReportLockboxToggleRemove'),
                                            'aria-label': intl.get(
                                                'ariaLabelReportLockboxToggleRemove'
                                            ),
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    };

    render() {
        const {ticket, reasonCodes, reasonCode, transactions, lockboxes, isSubmitting} = this.props;
        const isTransactionProofs = ticket && ticket.unlocalizedIssueType === 'Transaction Proofs';

        return (
            <div className="reportDetails" aria-busy={isSubmitting}>
                <Typography
                    variant="h5"
                    gutterBottom={true}
                    component="h3"
                    id="title-report-details">
                    {intl.get('reportDetails')}
                </Typography>

                <div className="details-pair" style={{margin: '24px 0'}}>
                    <span>
                        <strong>{intl.get('caseId')}: </strong>
                    </span>
                    <span>{ticket.caseId}</span>
                </div>

                {isSubmitting ? (
                    <CircularProgress
                        size={50}
                        aria-label={intl.get('ariaLabelCircularProgress')}
                        aria-valuemin={0}
                        aria-valuemax={1}
                    />
                ) : (
                    <div aria-live="polite">
                        {!isTransactionProofs && (
                            <div>
                                <h4 style={{margin: '10px 0 0 0'}}>
                                    {intl.get('reportedLockboxes')}
                                </h4>
                                {lockboxes.length > 0 ? (
                                    this.renderLockboxesToReport(lockboxes)
                                ) : (
                                    <p>{intl.get('noLockboxesReported')}</p>
                                )}
                            </div>
                        )}

                        <h4 style={{margin: '34px 0 0 0'}}>{intl.get('reportedTransactions')}</h4>
                        {transactions.length > 0 ? (
                            this.renderTransactionsToReport(transactions)
                        ) : (
                            <p>{intl.get('noTransactionsReported')}</p>
                        )}

                        {!isTransactionProofs && (
                            <div className="details-pair" style={{marginTop: 24}}>
                                <FormControl fullWidth={true}>
                                    <InputLabel
                                        htmlFor="select-resolution-type"
                                        id="label-for-select-resolution-type">
                                        {intl.get('resolution')}
                                    </InputLabel>
                                    <Select
                                        autoWidth={false}
                                        value={reasonCode}
                                        onChange={this.props.handleReasonCodeChange}
                                        SelectDisplayProps={{
                                            'aria-labelledby': 'label-for-select-resolution-type',
                                            id: 'select-resolution-type',
                                        }}>
                                        {reasonCodes.map((reason, i) => (
                                            <MenuItem key={i} value={reason.key}>
                                                {reason.title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default withAuthorization(['fraud', 'proofs', 'lockbox'])(ReportDetails);

import React from 'react';
import {connect} from 'react-redux';
import './CurrentUser.css';
import intl from 'react-intl-universal';

import Typography from '@material-ui/core/Typography';
import {isCallCenterAgent} from '../../reducers/authReducer';

class CurrentUser extends React.Component {
    renderKeyValuePair(key, value) {
        return (
            <div className="currentUserHolder">
                <span className="title">{key}</span>
                <span className="text">{value}</span>
            </div>
        );
    }

    render() {
        const {user, roles} = this.props;
        const coreId = user && user.dlbpUserId ? user.dlbpUserId : '-';
        const vmeId = user && user.verifiedMeUserId ? user.verifiedMeUserId : '-';
        const fullWidthStyle = isCallCenterAgent(roles) ? {width: '100%'} : {};

        return (
            <div className="currentUser" style={fullWidthStyle}>
                <Typography variant="h5" gutterBottom={true} component="h2">
                    {intl.get('currentUser')}
                </Typography>
                {this.renderKeyValuePair(`${intl.get('coreId')}:`, coreId)}
                {this.renderKeyValuePair(`${intl.get('vmeId')}:`, vmeId)}
            </div>
        );
    }
}

const mapStateToProps = store => ({
    roles: store.auth.roles,
});

export default connect(mapStateToProps)(CurrentUser);

import * as commonApi from '../api/common';
import {selectUser, addNewCase, fetchUser} from './userActions';
import {SearchUserBy} from '../enumerations/SearchUserBy';

export const fetchTickets = () => ({
    type: 'FETCH_TICKETS',
    payload: commonApi.findCasesForAgent(),
});

export const retrieveCase = caseId => dispatch => {
    dispatch({
        type: 'RETRIEVE_CASE',
        payload: commonApi.retrieveCase(caseId).then(ticket => {
            dispatch(startTicket(ticket));
            return ticket;
        }),
    });
};

export const startTicket = ticket => dispatch => {
    dispatch(selectTicket(ticket));
    dispatch({
        type: 'START_CASE',
        payload: commonApi.startCase(ticket.caseId),
        meta: ticket,
    });
};

export const clearTicket = () => ({
    type: 'CLEAR_TICKET',
});

export const clearNewTicketPending = () => ({
    type: 'CLEAR_NEW_TICKET_PENDING',
});

export const createNewTicket = (agentId, verifiedMeUserId, reasonCode) => dispatch => {
    dispatch({
        type: 'CREATE_NEW_TICKET',
        payload: commonApi.createCase(verifiedMeUserId, reasonCode).then(res => {
            return commonApi.retrieveCase(res.caseId).then(caseInfo => {
                dispatch(addNewCase(caseInfo));
                return caseInfo;
            });
        }),
    });
};

export const changeNewTicketReason = reason => ({
    type: 'CHANGE_NEW_TICKET_REASON',
    payload: reason,
});

export const orderCasesBy = key => ({
    type: 'ORDER_CASES_BY',
    payload: key,
});

export const selectTicket = ticket => dispatch => {
    dispatch({
        type: 'SELECT_TICKET',
        payload: ticket,
    });

    const isTransactionProofs = ticket && ticket.unlocalizedIssueType === 'Transaction Proofs';
    if (!isTransactionProofs) {
        dispatch(selectUser({verifiedMeUserId: ticket.verifiedMeUserId}));
        dispatch(fetchUser(SearchUserBy.VME_USER_ID, ticket.verifiedMeUserId));
    }
};

export const selectTicketById = id => ({
    type: 'SELECT_CASE_BY_ID',
    payload: id,
});

import * as commonApi from '../api/common';
import * as lockboxApi from '../api/lockbox';

import {selectUser} from './userActions';
import {populateCaseUrl} from '../reducers/transactionReducer';
import {isCallCenterAgent} from '../reducers/authReducer';

export const getLockboxes = (verifiedMeUserId, roles) => dispatch => {
    dispatch({
        type: 'FETCH_LOCKBOXES',
        payload: commonApi
            .getLockboxes(verifiedMeUserId)
            .then(res => {
                const lockboxes = res.lockboxes;
                if (lockboxes && lockboxes.length) {
                    dispatch(selectUser({dlbpUserId: lockboxes[0].dlbpUserId}));
                }
                return res;
            })
            .then(res => {
                if (isCallCenterAgent(roles)) {
                    return res;
                } else {
                    return populateCaseUrl(res.lockboxes).then(lbs => {
                        res.lockboxes = lbs;
                        return res;
                    });
                }
            }),
    });
};

export const setLockboxStatus = (caseId, lockboxId, status) => {
    return {
        type: 'SET_LOCKBOX_STATUS',
        payload: lockboxApi.setLockboxStatus(caseId, lockboxId, status),
        meta: {lockboxId, status},
    };
};

export const deleteLockbox = (caseId, lockboxId) => {
    return {
        type: 'DELETE_LOCKBOX',
        payload: lockboxApi.deleteLockbox(caseId, lockboxId),
        meta: {lockboxId},
    };
};

// TODO: remove
export const changeLockboxStatus = status => {
    return {
        type: 'CHANGE_LOCKBOX_STATUS',
        payload: status,
    };
};

export const setMarkedLockboxes = markedLockboxes => {
    return {
        type: 'SET_MARKED_LOCKBOXES',
        payload: markedLockboxes,
    };
};

export const clearMarkedLockboxes = () => {
    return {
        type: 'SET_MARKED_LOCKBOXES',
        payload: [],
    };
};

export const clearLockboxes = () => {
    return {
        type: 'CLEAR_LOCKBOXES',
        payload: [],
    };
};

//@flow
import React from 'react';
import './FooterNote.css';

export default class FooterNote extends React.Component {
    render() {
        return (
            <div className="footerNote">
                <p>{this.props.text}</p>
            </div>
        );
    }
}
